import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useScreen } from '../context';
import { Logout } from '../auth';
import LinkWithDirtyProtection from './linkWrapper';

const Sidenav = () => {
  const [open, setOpen] = useState(false);
  const { screenId, navCollapsed, toggleNavCollapsed, setLiveChatOpen } = useScreen();

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <div 
        className={`sidenav fixed flex-grow px-4 pt-6 pb-2 text-white flex flex-col top-0 md:h-screen`}
        style={{ width: navCollapsed ? '80px' : '200px' }}
      >
        <div className={`flex ${navCollapsed ? "justify-center" : "justify-between ml-2"}`}>
          <button
            onClick={() => {
              setOpen(!open)
              toggleNavCollapsed()
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>

        <div className={`${open ? "flex flex-col" : "hidden md:flex flex-col h-full"}`} data-testid="sidenav-main-contents">

          <div className="flex flex-col mt-12">
          {[
              { title: 'Dashboard', link: '/', icon: 'home' },
              //{ title: 'Surge', link: '/surge', icon: 'chart-line' },
              { title: 'Maintenance', link: '/maintenance', icon: 'wrench' },
              { title: 'Products', link: '/products', icon: 'box' }, 
              { title: 'Purchasing', link: '/purchasing', icon: 'cart-shopping' },
              { title: 'EQSI', link: '/eqsi', icon: 'basket-shopping' },
              { title: 'Reporting', link: '/reporting', icon: 'file-chart-column' },
              { title: 'System Config', link: '/config', icon: 'cog' },
              { title: 'Users & Roles', link: '/users', icon: 'user-group-crown'  }
            ].map((child, index) => (
              <LinkWithDirtyProtection
                to={child.link}
                className={`flex gap-4 items-center p-2 cursor-pointer bg-opacity-10 ${currentPath === child.link ? "bg-white" : "hover:bg-white hover:text-black"} ${navCollapsed ? "justify-center" : ""}`}
                key={index}
              >
                <p>
                  <i className={`fa-solid fa-${child.icon} ${navCollapsed ? "text-lg" : ""}`}></i>
                  <span className={`${navCollapsed ? "hidden" : ""}`}>&nbsp;&nbsp;{child.title}</span>
                </p>
              </LinkWithDirtyProtection>
            ))}
          </div>

          <div
            className={`flex gap-4 items-center p-2 cursor-pointer bg-opacity-10 mt-auto ${navCollapsed ? "justify-center" : ""} hover:bg-white hover:text-black`}
            onClick={() => setLiveChatOpen(true)}
          >
            <p>
              <i className={`fa-solid fa-message-bot ${navCollapsed ? "text-lg" : ""}`}></i>
              <span className={`${navCollapsed ? "hidden" : ""}`}>&nbsp;&nbsp;AI Support</span>
            </p>
          </div>

          <p className={`uppercase font-bold text-sm mt-4 ${navCollapsed ? "hidden" : "block"}`}>Settings</p>

          <div className={`h-[1px] w-full bg-white bg-opacity-60 my-2 ${navCollapsed ? "hidden" : "block"}`} />

          <div className={`flex items-center shrink-0 ${navCollapsed ? "justify-center mt-2" : "gap-4 p-2"}`}>
            <img src="/tayah.png" className="rounded-full h-10 w-10" alt="profile" />
            
            <div className={`${navCollapsed ? "hidden" : "block"}`}>
              <p>Ethan</p>
              <p className="text-muted text-xs">H3O</p>
            </div>
            

            <button onClick={() => Logout()} className={`${navCollapsed ? "hidden" : "ml-auto"}`}><i className="fa-solid fa-arrow-right-from-bracket"></i></button>
          </div>

          <p className="text-center text-[9px] text-muted mt-2 uppercase">Screen {screenId}</p>
        </div>
      </div>

      <div style={{ width: navCollapsed ? '85px' : '225px' }} />
    </>
  );
}

export default Sidenav;