import { formatNumber } from "../../utils";

type MetricChangeProps = {
  metric: string;
  metricChange: number;
  giveTextColor?: boolean;
}

const MetricChangeHandler = ({ metric, metricChange, giveTextColor }: MetricChangeProps) => {

  let leadingString = "";

  if (metric === "Sales" || metric === "Profit") {
    if (metricChange < 0) {
      leadingString += "-";
      metricChange = Math.abs(metricChange);
    }
    else {
      leadingString += "+";
    }
    leadingString += "£"
  } else if (metricChange > 0) {
      leadingString += "+";

  }

  return (
    <span className="text-widget-blue font-bold">
      {leadingString}
      {formatNumber(metricChange)}
    </span>
  )

}

export default MetricChangeHandler;