import { Page, Text, Document, StyleSheet, View, Image, Font } from '@react-pdf/renderer';
import { formatNumber } from '../../../utils';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 25,
  },
  image: {
    height: 50,
  },
  headerText: {
    fontSize: 18,
    marginLeft: 10,
  },
  sectionHeader: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 16,
  },
  miniHeader: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 10,
    marginTop: 10,
  },
  chartImage: {
    width: '100%',
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  flexGrow: {
    flexGrow: 1,
  },
  imageParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentCard: {
    padding: 10,
    marginBottom: 10,
    border: 1,
    borderColor: '#d3d3d3',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    borderRadius: 10,
  },
  divider: {
    margin: '3px 0',
    borderBottom: 1,
    borderColor: '#000',
    width: '100%',
    height: 1,
  }

});

interface SalesReportProps {
  orderImageUrl: string;
  salesImageUrl: string;
  profitImageUrl: string;
  totalOrders: { thisYear: number, lastYear: number };
  totalSales: { thisYear: number, lastYear: number };
  totalProfit: { thisYear: number, lastYear: number };
}

const ReportTemplate: React.FC<SalesReportProps> = ({ 
  orderImageUrl, 
  salesImageUrl, 
  profitImageUrl,
  totalOrders,
  totalSales,
  totalProfit,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <View style={styles.header}>
          <Image style={styles.image} src="/hdm-logo.png" />
          <Text style={styles.headerText}>Yearly Sales Report</Text>
        </View>

        <Text style={styles.sectionHeader}>Sales Orders</Text>

        <View style={styles.divider} />

        <View style={styles.row}>
          <View>
            <Text style={styles.miniHeader}>Number of Sales</Text>
            <Text>This Year: {formatNumber(totalOrders.thisYear)}</Text>
            <Text>Last Year: {formatNumber(totalOrders.lastYear)}</Text>
            <Text>Change: {formatNumber((totalOrders.thisYear - totalOrders.lastYear))}</Text>

            <Text style={styles.miniHeader}>Monthly Average</Text>
            <Text>This Year: {formatNumber(totalOrders.thisYear / 12)}</Text>
            <Text>Last Year: {formatNumber(totalOrders.lastYear / 12)}</Text>
            <Text>Change: {formatNumber((totalOrders.thisYear - totalOrders.lastYear) / 12)}</Text>
          </View>

          <View style={[styles.flexGrow, styles.imageParent]}>
            <Image style={styles.chartImage} src={orderImageUrl} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ReportTemplate;