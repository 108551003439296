import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';

type ScreenContextType = {
  screenId: string;
  navCollapsed: boolean;
  setScreenId: (id: string) => void;
  toggleNavCollapsed: () => void;
  pageHasDirtyForm: boolean;
  setPageHasDirtyForm: (dirty: boolean) => void;
  liveChatOpen: boolean;
  setLiveChatOpen: (open: boolean) => void;
}

const ScreenContext = createContext<ScreenContextType>({
  screenId: '',
  navCollapsed: false,
  setScreenId: () => {},
  toggleNavCollapsed: () => {},
  pageHasDirtyForm: false,
  setPageHasDirtyForm: () => {},
  liveChatOpen: false,
  setLiveChatOpen: () => {},
});


export const ScreenProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [screenId, setScreenId] = useState<string>('');
  const [navCollapsed, setNavCollapsed] = useState<boolean>(false);
  const [pageHasDirtyForm, setPageHasDirtyForm] = useState<boolean>(false);
  const [liveChatOpen, setLiveChatOpen] = useState<boolean>(false);

  const toggleNavCollapsed = () => {
    setNavCollapsed(!navCollapsed);
  }

  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    console.log("Page has dirty form: ", pageHasDirtyForm);
    if (pageHasDirtyForm) {
      event.preventDefault();
      event.returnValue = ''; // This triggers the confirmation dialog
    }
  }, [pageHasDirtyForm]);

  // Effect to set up the beforeunload event listener for browser tab or window closing
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  return (
    <ScreenContext.Provider value={{ screenId, setScreenId, navCollapsed, toggleNavCollapsed, pageHasDirtyForm, setPageHasDirtyForm, liveChatOpen, setLiveChatOpen }}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreen = () => useContext(ScreenContext);