import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useScreen } from '../context';

import { fetchSupplier, createSupplier } from '../api';

import BackButton from '../components/backLink';

const SuppliersCard = () => {
  const { id: supplierId } = useParams<{ id: string }>();
  const newSupplier = supplierId === "new";

  const tabOptions = ["Overview", "Supplier Notes"];
  const [tab, setTab] = useState(tabOptions[0]);
  const [fetchingData, setFetchingData] = useState(true);

  const { setScreenId, setPageHasDirtyForm } = useScreen();
  setScreenId(`PO- ${supplierId}`)

  const [customerData, setCustomerData] = useState({
    NAME: "",
    PHONE: "",
    CONTACT: "",
  } as { [key: string]: any });

  useEffect(() => {
    if (newSupplier || !supplierId) {
      setFetchingData(false);
      return
    }
    
    const fetchData = async () => {
      try {
        const data = await fetchSupplier(supplierId);
        setCustomerData(data[0]);
        console.log(data);
      } catch (error) {
        console.error('There was a problem fetching accounts:', error);
      }
      setFetchingData(false);
    };

    fetchData();
  }, [supplierId]);

  const inputVariable = (label: string, valueKey: string) => {
    return <>
      <div className="label">{label}</div>
      <input 
        type="text" 
        className="input" 
        placeholder={`${label}...`} 
        value={customerData[valueKey]} 
        onChange={(e) => {
          setPageHasDirtyForm(true);
          setCustomerData({ ...customerData, [valueKey]: e.target.value })
        }}
      />
    </>
  }

  const attemptSave = async () => {
    try {
      let createResponse = await createSupplier(customerData);
      console.log(createResponse);
      //window.location.href = "/maintenance";
    } catch (error) {
      console.error('There was a problem saving the supplier:', error);
    }
  }

  if (fetchingData) {
    return (
      <div className="dashboard-card w-full h-full p-4 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center flex-grow">
          <i className="fa-sharp fa-circle-notch fa-spin text-4xl"></i>
          <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
        </div>
      </div>
    );
  }

  return (
  <div className="flex flex-col gap-6">
    <BackButton />
    <div className="flex w-full items-center gap-4 dashboard-card p-4">
      {newSupplier 
        ? <>
            <h2 className="font-semibold text-2xl">New Supplier</h2>

            <button
              className="btn btn-secondary ml-auto"
              onClick={() => {
                window.location.href = "/maintenance";
              }}
            >
              Discard
            </button>

            <button
              className="btn btn-primary"
              onClick={attemptSave}
            >
              Save
            </button>
          </>
        : <>
            <div>
              <p>Supplier</p>
              <h2 className="font-semibold text-2xl">{customerData.NAME}</h2>
            </div>

            <button
              className="btn btn-primary ml-auto"
              onClick={attemptSave}
            >
              Save
            </button>
        </>
      }
    </div>

    <div className="dashboard-card bg-base-100 flex-grow p-4 flex flex-col">
      {customerData 
        ? <>
            <div className="hidden md:flex">
              {tabOptions.map((option, index) => (
                <button key={index} className={`px-2 pb-2 border-b-2 ${tab === option ? 'border-[#1366D9]' : 'border-[#F0F1F3]'} hover:border-[#1366D9]`} onClick={() => setTab(option)}>{option}</button>
              ))}
              <div className="flex-grow border-b-2 border-[#F0F1F3]" />
            </div>

            <select className="block md:hidden pb-2 border-b-2 border-[#F0F1F3] px-2" onChange={(e) => setTab(e.target.value)}>
              {tabOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>

            {tab === "Overview" && (
              <div className="grid md:grid-cols-2 gap-6 mt-6">
                <div className="div">
                  <h3 className="font-semibold">Primary Details</h3>

                  <div className="form-container mt-4">
                    {inputVariable("Supplier Name", "NAME")}
                    {inputVariable("Phone", "PHONE")}
                    {inputVariable("Contact", "CONTACT")}
                  </div>
                </div>
              </div>
            )}
          </>
        :  <div className="flex flex-col justify-center items-center flex-grow">
            <i className="fa-sharp fa-solid fa-circle-exclamation text-4xl"></i>
            <h3 className="font-semibold text-lg md:text-2xl ml-2">Couldn't fetch data...</h3>
          </div>
      }
    </div>
  </div>);
}

export default SuppliersCard;