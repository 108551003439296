import React, { useEffect, useState } from 'react';
import LinkWithDirtyProtection from '../components/linkWrapper';

import LightMetricCard from '../components/widgets/lightMetricCard';
import PrimaryTable from '../components/widgets/primaryTable';
import Tooltip from '../components/widgets/tooltip';
import { useScreen } from '../context';

import { eqsiData } from '../dummyData';

const EqsiPage = () => {
  const tabOptions = ["All", "Quotes", "Sales Orders", "Invoiced", "Completed"];
  const [fetching, setFetching] = useState(false);

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("EQSI");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  return (
    <div className="flex flex-col flex-grow gap-4">
      {!fetching && (
        <>
          <div className="grid md:grid-cols-4 gap-4">
            <LightMetricCard title="Total Quotes" count={4690} icon="square-quote" chartColor="blue" />
            <LightMetricCard title="Sales Orders" count={281} icon="bag-shopping" chartColor="yellow" />
            <LightMetricCard title="Converted Quotes" count={14} icon="box-open-full" chartColor="black" />
            <LightMetricCard title="Completed" count={4690} icon="box-check" chartColor="green" />
          </div>

          <PrimaryTable
            source="EQSI"
            itemData={eqsiData}
            tabOptions={tabOptions}
            actionButtons={<LinkWithDirtyProtection to="/eqsi/sales-order/">
              <Tooltip content="Create a new ESQI for one of your customers." position="top" additionalClasses='h-full'>
                <LinkWithDirtyProtection className="btn btn-primary h-full" to="/eqsi/sales-order/NEW">
                  <i className="fa-solid fa-plus"></i><span className="hidden md:block">&nbsp;&nbsp;EQSI</span>
                </LinkWithDirtyProtection>
              </Tooltip>
            </LinkWithDirtyProtection>}
          />
        </>
      )}
    </div>
  );
}

export default EqsiPage;