import React, { useState } from 'react';

type PaginationProps = {
  numberOfEntries: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  itemsPerPage: number;
};

const Pagination = ({ numberOfEntries, currentPage, setCurrentPage, itemsPerPage }: PaginationProps) => {

  return (
    <div className="flex items-center mt-4 gap-4">
      <div className="flex gap-2">
        <button 
          className={`btn btn-secondary ${currentPage === 1 ? 'disabled:opacity-50' : ''}`}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <button 
          className={`btn btn-secondary ${currentPage * itemsPerPage >= numberOfEntries ? 'disabled:opacity-50' : ''}`}
          disabled={currentPage * itemsPerPage >= numberOfEntries}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
      <p className="text-[#667085] text-xs">Showing {currentPage * itemsPerPage - itemsPerPage + 1} to  {currentPage * itemsPerPage > numberOfEntries ? numberOfEntries : currentPage * itemsPerPage}.</p>
    </div>
  )
}

export default Pagination;