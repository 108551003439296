import React, { useState, useEffect } from "react";
import { Order } from "../../types";
import Pagination from "../pagination";
import { formatNumber } from "../../utils";
import { Link } from "react-router-dom";

import { fetchMetaDataForTopProducts } from "../../api";

type DataViews = 'Top Selling Products' | 'Stock to Clear';

const TopProducts = ({ ordersThisYear }: {ordersThisYear: Order[]}) => {
  const [dataView, setDataView] = useState('Top Selling Products' as DataViews);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPeriodDropdown, setShowPeriodDropdown] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(5);
  const [topProducts, setTopProducts] = useState([] as any[]);
  const [topProductsMetaData, setTopProductsMetaData] = useState({} as any);

  const handleDataViewChange = (view: DataViews) => {
    setDataView(view);
    setShowDropdown(false);
  };

  const processOrdersIntoTopProducts = (orders: Order[], numberOfMonths: number) => {
    const now = new Date();
    const startDate = new Date(now.setMonth(now.getMonth() - numberOfMonths));

    const handleProduct = (productId: string, quantity: number, sales: number, acc: any) => {
      if (acc[productId]) {
        acc[productId].quantity += quantity;
        acc[productId].sales += sales;
      } else {
        acc[productId] = {
          id: productId,
          quantity: quantity,
          sales: sales
        };
      }
    }

    const topProducts = orders
      .filter(order => order.formattedDate >= startDate)
      .reduce((acc, order) => {
        if (typeof order["PRODUCT.CODE"] === 'string' && typeof order["ORDER.QUANTITY"] === 'string') {
          handleProduct(
            order["PRODUCT.CODE"], 
            parseFloat(order["ORDER.QUANTITY"]), 
            order["LINE.VALUE"] as number,
            acc
          );
        }
        else if (typeof order["PRODUCT.CODE"] === 'object' && typeof order["LINE.VALUE"] === 'object') {
          order["PRODUCT.CODE"].forEach((productId, index) => {
            handleProduct(
              productId, 
              parseFloat(order["ORDER.QUANTITY"][index]),
              parseFloat((order["LINE.VALUE"] as string[])[index]),
              acc
            );
          });
        }
        return acc;
      }, {} as { [key: string]: { id: string, name: string, quantity: number, sales: number } });

    return Object.values(topProducts)
      .filter(product => product["id"] !== "")
      .sort((a, b) => b.sales - a.sales)
      .slice(0, 25);
  }

  useEffect(() => {
    if (ordersThisYear.length === 0) return;

    async function fetchData() {
      let formattedtopProducts = processOrdersIntoTopProducts(ordersThisYear, numberOfMonths);
      console.log(formattedtopProducts);
      //const uniqueProductIds = new Set(formattedtopProducts.map(product => product.id));
      //console.log(uniqueProductIds);
      //let filteredUniqueProducts = Array.from(uniqueProductIds).filter((productId) => productId !== "GIV-BAT 9.5 G2");
      //const metaData = await fetchMetaDataForTopProducts(filteredUniqueProducts.slice(0, 2));
      //console.log(metaData)

      setTopProducts(formattedtopProducts);
      //setTopProductsMetaData(metaData);
    }

    fetchData();
  }, [ordersThisYear, numberOfMonths]);

  return (
    <div className="dashboard-card bg-base-100 p-4 h-full">
      <div className="flex items-center gap-4">
        <h3 className="font-bold mr-auto">{dataView}</h3>

        {dataView === 'Top Selling Products' && (
          <div className="relative z-10">
            <div className="cursor-pointer flex gap-1 items-center" onClick={() => setShowPeriodDropdown(!showPeriodDropdown)}>
              <p className="text-sm text-muted">Last {numberOfMonths} month{numberOfMonths > 1 ? 's' : ''}</p>
              <i className="fa-regular fa-chevron-down text-muted hover:text-black"></i>
            </div>
            
            {showPeriodDropdown && (
              <div className="absolute right-0 w-52 bg-white border border-gray-200 rounded shadow-md z-50">
                <div className="px-4 py-2">
                  <label htmlFor="months-slider" className="block text-sm font-medium text-gray-700">
                    Select number of months
                  </label>
                  <input
                    id="months-slider"
                    type="range"
                    min="1"
                    max="12"
                    value={numberOfMonths}
                    onChange={(e) => setNumberOfMonths(parseInt(e.target.value))}
                    className="w-full mt-2"
                  />
                  <div className="flex justify-between text-xs text-gray-600 mt-1">
                    <span>1</span>
                    <span>12</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="relative z-10">
            <i 
              className="cursor-pointer fa-regular fa-ellipsis-vertical text-muted ml-auto hover:text-black" 
              onClick={() => setShowDropdown(!showDropdown)}
            ></i>
            {showDropdown && (
              <div className="absolute right-0 w-48 bg-white border border-gray-200 rounded shadow-md z-50">
                <ul className="">
                  {[ 'Top Selling Products', 'Stock to Clear' ].map((view, index) => (
                    <li 
                      className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${dataView === view ? 'bg-blue-100' : ''}`}
                      onClick={() => handleDataViewChange(view as DataViews)}
                    >
                      {view}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
      </div>

      <table className="table-auto w-full mt-4">
        <thead>
          <tr>
            <th className="text-left px-1 text-sm">Product</th>
            <th className="text-left px-1 text-sm">Sales</th>
            <th className="text-left px-1 text-sm">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {topProducts
            .slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage)
            .map((product, index) => (
              <tr key={index}>
                <td>
                  <Link className="font-semibold text-sm hover:underline" to={`/product/${product.id}?back=/`}>
                    {product.id}
                  </Link>
                </td>
                <td><p className="text-sm px-1">£{formatNumber(product.sales)}</p></td>
                <td><p className="text-sm px-1">{formatNumber(product.quantity)}</p></td>
              </tr>
            ))}
        </tbody>
      </table>

      <Pagination 
        currentPage={currentPage}
        numberOfEntries={topProducts.length}
        setCurrentPage={setCurrentPage}
        itemsPerPage={productsPerPage}
      />
    </div>
  )

}

export default TopProducts;