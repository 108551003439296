import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useScreen } from '../context';

import { fetchCustomer, updateCustomer } from '../api';
import { Customer } from '../types';

import BackButton from '../components/backLink';

const CustomersCard = () => {
  const { id: customerId } = useParams<{ id: string }>();
  const newCustomer = customerId === "new";

  const tabOptions = ["Overview", "Customer Notes"];
  const [tab, setTab] = useState(tabOptions[0]);
  const [customerData, setCustomerData] = useState({} as Customer);
  const [fetchingData, setFetchingData] = useState(true);
  const [originalData, setOriginalData] = useState({} as Customer);
  const [errorMessage, setErrorMessage] = useState("");

  const { setScreenId, setPageHasDirtyForm } = useScreen();
  setScreenId(`CUSTOMER- ${customerId}`);

  useEffect(() => {
    if (!customerId) return;

    const fetchData = async () => {
      try {
        const data = await fetchCustomer(customerId);
        console.log(data);

        if (data) {
          setOriginalData(data);
          setCustomerData(data);
        }
      } catch (err) {
        console.error('There was a problem fetching accounts:', err);
        setErrorMessage(String(err));
      }
      setFetchingData(false);
    };

    fetchData();
  }, [customerId]);

  const primaryDetailsFields = [
    { label: "Customer name", key: "Name", value: customerData.NAME },
    { label: "Address", key: "Address", value: customerData.ADDRESS },
    { label: "Phone", key: "Phone", value: customerData.PHONE }
  ]

  const updateValue = (key: keyof Customer, value: string) => {
    setPageHasDirtyForm(true);
    setCustomerData({ ...customerData, [key]: value });
  }

  const updateCustomerData = async () => {
    if (!customerId) return;

    try {
      const response = await updateCustomer(customerId, originalData, customerData);
      setPageHasDirtyForm(false);
      console.log(response);
    } catch (err) {
      console.log(err)
      setErrorMessage(String(err));
      console.error('There was a problem updating customer:', err);
    }
  }

  const attemptSave = async () => {
    console.log("attempting to save");
  }

  return (
    <div className="flex flex-col gap-6 items-start">
      <BackButton />

      <div className="flex w-full items-center gap-4 dashboard-card p-4">
        {newCustomer 
          ? <>
              <h2 className="font-semibold text-2xl">New Customer</h2>

              <button
                className="btn btn-secondary ml-auto"
                onClick={() => {
                  window.location.href = "/maintenance";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-primary"
                onClick={attemptSave}
              >
                Save
              </button>
            </>
          : <>
              <div>
                <p>Customer</p>
                <h2 className="font-semibold text-2xl">{customerData.NAME}</h2>
              </div>

              <button
                className="btn btn-primary ml-auto"
                onClick={attemptSave}
              >
                Save
              </button>
          </>
        }
      </div>

      <div className="dashboard-card w-full bg-base-100 p-4 flex flex-col">
        
        {fetchingData &&  (
          <div className="flex flex-col justify-center items-center flex-grow">
            <i className="fa-solid fa-spinner fa-spin text-4xl"></i>
            <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
          </div>
        )}

        {!fetchingData && (
        
          customerData 
          ? <>
              <div className="hidden md:flex mt-4">
                {tabOptions.map((option, index) => (
                  <button key={index} className={`px-2 pb-2 border-b-2 ${tab === option ? 'border-[#1366D9]' : 'border-[#F0F1F3]'} hover:border-[#1366D9]`} onClick={() => setTab(option)}>{option}</button>
                ))}
                <div className="flex-grow border-b-2 border-[#F0F1F3]" />
              </div>

              <select className="block md:hidden mt-4 pb-2 border-b-2 border-[#F0F1F3] px-2" onChange={(e) => setTab(e.target.value)}>
                {tabOptions.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>

              {tab === "Overview" && (
                  <div className="div mt-6">
                    <h3 className="font-semibold">Primary Details</h3>

                    <div className="form-container mt-4">
                      {primaryDetailsFields.map((field, index) => (
                        <>
                          <div className="label">{field.label}</div>
                          <input 
                            type="text" 
                            className="input" 
                            placeholder={`${field.label}...`} 
                            value={field.value}
                            onChange={(e) => updateValue(field.key as keyof Customer, e.target.value)}
                          />
                        </>
                      ))}
                    </div>
                  </div>
              )}

              

              <div className="mt-12">
                {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}

                <button className="btn btn-outline" onClick={() => updateCustomerData()}>
                  <i className="fa-solid fa-pencil"></i>&nbsp;&nbsp;Save
                </button>
              </div>
            </>
          :  <div className="flex flex-col justify-center items-center flex-grow">
              <i className="fa-sharp fa-solid fa-circle-exclamation text-4xl"></i>
              <h3 className="font-semibold text-lg md:text-2xl ml-2">Couldn't fetch data...</h3>
            </div>
        )}
      </div>
    </div>
  );
}

export default CustomersCard;