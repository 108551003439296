import React, { useEffect, useState } from 'react';
import { useScreen } from '../context';
import { Link } from 'react-router-dom';

import LightMetricCard from '../components/widgets/lightMetricCard';
import PageTabs from '../components/pageTabs';
import SearchBar from '../components/searchBar';
import Pagination from '../components/pagination';
import Tooltip from '../components/widgets/tooltip';

import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd } from "../utils";
import { Product } from '../types';

import { searchProducts } from '../api';

const ProductPage = () => {
  const tabOptions = ["All Products", "Low Stock"];
  const [tab, setTab] = useState(tabOptions[0] as string);
  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("PRODUCTS");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products, productCategories } = await fetchProductData();
        setProductData(products);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="grid md:grid-cols-3 gap-4">
        <LightMetricCard title="Total Products" count={2500} icon="warehouse-full" chartColor="green" />
        <LightMetricCard title="Unpublished" count={0} icon="clock" chartColor="yellow" />
        <LightMetricCard title="Low Stock" count={0} icon="circle-exclamation" chartColor="red" />
      </div>
      
      {fetching && 
        <div className="flex justify-center items-center h-full">
          <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
        </div>
      }

      {!fetching && <>
        <div className="flex justify-between items-center flex-wrap gap-4">
          <PageTabs tabs={tabOptions} activeTab={tab} setActiveTab={setTab} card={true} />
          <SearchBar initialData={productData} setResults={setFilteredAndSearchedProducts} searchFunction={searchProducts} childQuery=''/>
        </div>
        <div className="flex flex-col gap-4 dashboard-card p-2 md:p-4 rounded-lg">
          <div>
            <table className="table-auto w-full">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th className="text-left px-1 text-sm">Product</th>
                  <th className="text-left px-1 text-sm">SKU</th>
                  <th className="text-left px-1 text-sm">Supplier</th>
                  <th className="text-left px-1 text-sm">LIST.PRICE</th>
                  <th className="text-left px-1 text-sm">Cost Price</th>
                  <th className="text-left px-1 text-sm">Stock</th>
                </tr>
              </thead>
              <tbody>
                {sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, "DESCRIPTION")
                  .sort((a: Product, b: Product) => {
                    if (a["Total Stock"] > 0 && b["Total Stock"] === 0) return -1;
                    if (a["Total Stock"] === 0 && b["Total Stock"] > 0) return 1;
                    return 0;
                  })
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map((product: Product, rowIndex: number) => (
                    <tr key={rowIndex}>
                      <td>
                        <Link className="flex gap-4 items-center pr-2 hover:underline" to={`/product/${product["PRODUCT.CODE"]}`}>
                          <img src={product["PICT.FLAG"]} alt={product.DESCRIPTION} className="w-14 h-14 rounded-md" />
                          <div>
                            <p className="font-semibold text-sm">{product.DESCRIPTION.length > 45 ? product.DESCRIPTION.slice(0, 45) + "..." : product.DESCRIPTION}</p>
                            <p className="text-xs">{product["PROD.GROUP"]}</p>
                          </div>
                        </Link>
                      </td>
                      <td><Link className="flex gap-4 items-center pr-2 hover:underline" to={`/product/${product["PRODUCT.CODE"]}`}>
                            <p className="text-sm text-[#3A5BFF]">{product["PRODUCT.CODE"]}</p>
                          </Link>
                      </td>
                      <td><p className="text-sm px-1">{product["SUPPLIER"]}</p></td>
                      <td><p className="text-sm px-1">£{product["COST.PRICE"]}</p></td>
                      <td><p className="text-sm px-1">£{product["LIST.PRICE"]}</p></td>
                      <td><p className="text-sm px-1">{product["Total Stock"]}</p></td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          
          <Pagination 
            numberOfEntries={productData.length} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </>}
    </div>
  );
}

export default ProductPage;