import html2canvas from 'html2canvas';

const exportChartToImage = async (chartElementId: string) => {
  const chartElement = document.getElementById(chartElementId);
  if (!chartElement) {
    throw new Error(`Element with id ${chartElementId} not found`);
  }
  const canvas = await html2canvas(chartElement);
  return canvas.toDataURL('image/png');
};

export default exportChartToImage;