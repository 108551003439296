type SimpleMetricCardProps = {
  title: string;
  count: number;
  icon: string;
  chartColor: "pink" | "green" | "black" | "blue" | "yellow" | "red";
}

const SimpleMetricCard = ({ title, count, icon, chartColor }: SimpleMetricCardProps) => {
  return (
    <div className="dashboard-card bg-base-100">
      <div className="flex items-center p-4 gap-4 justify-between">
        <div>
          <h3 className="font-bold text-xl md:text-4xl">
            {count > 10000 ? `${(count / 1000).toFixed(0)}K` : count.toLocaleString()}
          </h3>
          <p className="text-muted text-xs mt-1">{title}</p>
        </div>

        <div className="bg-widget-blue text-widget-blue rounded-xl p-2 md:p-3 md:px-4 text-xl md:text-2xl">
          <i className={`fas fa-${icon} md:text-3xl`} data-testid="icon-testid"></i>
        </div>
      </div>
    </div>
  )

}

export default SimpleMetricCard;