import React, { useEffect, useState } from 'react';
import { useScreen } from '../context';
import { Link } from 'react-router-dom';

import LightMetricCard from '../components/widgets/lightMetricCard';
import NewPurchase from '../components/purchaseOrder';
import Pagination from '../components/pagination';
import SearchBar from '../components/searchBar';
import Tooltip from '../components/widgets/tooltip';
import PageTabs from '../components/pageTabs';

import { listPurchaseOrders, searchProducts } from '../api';

import { PurchaseOrder } from '../types';
import LinkWithDirtyProtection from '../components/linkWrapper';

const EqsiPage = () => {
  const tabs = ["All", "Draft", "Ordered", "Partial Received", "Fully Received", "Paid"];
  const [activeTab, setActiveTab] = useState(tabs[0] as string);
  const [newEQSIopen, setNewEQSIopen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [orderData, setOrderData] = useState([] as any[]);
  const [filteredAndSearchedOrders, setFilteredAndSearchedOrders] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("PURCHASING");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  useEffect(() => {
    async function fetchOrderData() {
      setFetching(true);
      const orders = await listPurchaseOrders();
      console.log(orders);
      setOrderData(orders);
      setFilteredAndSearchedOrders(orders);
      setFetching(false);
    }

    fetchOrderData();
  }, []);

  return (
    <div className="flex flex-col flex-grow gap-4">
      {!fetching && <>
        <div className="grid md:grid-cols-4 gap-4">
          <LightMetricCard title="Total Quotes" count={4690} icon="square-quote" chartColor="blue" />
          <LightMetricCard title="Sales Orders" count={281} icon="bag-shopping" chartColor="yellow" />
          <LightMetricCard title="Converted Quotes" count={14} icon="box-open-full" chartColor="black" />
          <LightMetricCard title="Completed" count={4690} icon="box-check" chartColor="green" />
        </div>

        <div className="flex gap-4 items-center flex-wrap justify-between">
          <Tooltip content="Create a new ESQI for one of your customers." position="top" additionalClasses='h-full'>
            <LinkWithDirtyProtection className="btn btn-primary h-full" to="/purchasing/po/NEW">
              <i className="fa-solid fa-plus"></i><span className="hidden md:block">&nbsp;&nbsp;PO</span>
            </LinkWithDirtyProtection>
          </Tooltip>

          <PageTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} card={true} />

          <SearchBar initialData={orderData} setResults={setFilteredAndSearchedOrders} searchFunction={searchProducts} childQuery='' />
        </div>

        <div className="flex flex-col gap-4 dashboard-card bg-base-100 p-2 md:p-4 rounded-lg">
          <div>
            <table className="table-auto w-full">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th className="text-left px-1 text-sm">Number</th>
                  <th className="text-left px-1 text-sm">Date</th>
                  <th className="text-left px-1 text-sm">Value</th>
                  <th className="text-left px-1 text-sm">Supplier</th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSearchedOrders
                  .filter((purchaseOrder: any) => activeTab === "All" ? true : purchaseOrder.status.includes(activeTab))
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((purchaseOrder: PurchaseOrder, rowIndex: number) => (
                  <tr key={rowIndex}>
                    <td>
                      <Link className="text-sm text-[#3A5BFF] hover:underline" to={`/purchasing/po/${purchaseOrder["P.ORDER.NO"]}`}>
                        {purchaseOrder["P.ORDER.NO"]}
                      </Link>
                    </td>
                    <td className="text-sm">
                      <div className="flex gap-2">
                        <p>{purchaseOrder["ORDER.DATE"]}</p>
                      </div>
                    </td>
                    <td className="text-sm">
                      <p>£{parseInt(purchaseOrder["BALANCE"])}</p>
                    </td>
                    <td className="text-sm">
                      <Link className="text-sm text-[#3A5BFF] hover:underline" to={`/supplier/${purchaseOrder["SUPPLIER.CODE"]}`} target='_blank'>
                        {purchaseOrder["SUPPLIER.CODE"]}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <Pagination 
            numberOfEntries={orderData.length} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </>}
    </div>
  );
}

export default EqsiPage;