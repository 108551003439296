import React from 'react';
import { useScreen } from '../../context';
import LinkWithDirtyProtection from '../../components/linkWrapper';

const ReportingPage = () => {
  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("CONFIG");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  const configOptions = [
    {
      "title": "Yearly Sales Comparison",
      "link": "/reports/yearly-sales",
      "description": "View and compare sales data from previous years.",
      "icon": "bar-chart"
    }
  ];

  return (
    <div>
      <h1 className="text-2xl md:text-4xl font-bold">System Config</h1>
      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
        {configOptions.map((child, index) => (
          <LinkWithDirtyProtection
            to={child.link}
            className="flex flex-col dashboard-card bg-base-100 p-4 cursor-pointer hover:bg-gray-100"
            key={index}
          >
            <div className="flex items-center gap-4">
              <div className="bg-widget-blue text-widget-blue rounded-xl p-2 md:p-3 md:px-4 text-xl md:text-2xl">
                <i className={`fas fa-${child.icon}`}></i>
              </div>

              <h3 className="text-lg md:text-2xl font-semibold">
                {child.title}
              </h3>
            </div>
            
            <p className="text-sm md:text-lg mt-4">{child.description}</p>
          </LinkWithDirtyProtection>
        ))}
      </div>
    </div>
  )
}

export default ReportingPage;