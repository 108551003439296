import { useEffect } from 'react';
import { fetchProducts } from './api';
import { Product } from './types';

export const ucFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type KeyPressAction = () => void;

export const useKeyPress = (targetKey: string, action: KeyPressAction): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      console.log(event.key);
      if (event.key === targetKey) {
        event.preventDefault(); // Prevent default browser behavior if needed
        action(); // Execute the provided callback function
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [targetKey, action]);
};

export const fetchProductData = async () => {
  const data: Product[] = await fetchProducts();

  const products = data.filter((product: Product) => !product["PRODUCT.CODE"].includes("(") && !product["PRODUCT.CODE"].includes(")"));

  const productCategories: string[] = [];

  products.forEach((product: Product) => {
    if (!product["PRODUCT.CODE"] || product["PRODUCT.CODE"] === "") {
      console.log("Product code missing for product:", product);
    }

    if (!productCategories.includes(product["PROD.GROUP"])) productCategories.push(product["PROD.GROUP"]);
    if ((product["BOURNEMOUTH"] && product["BOURNEMOUTH"] !== "") &&  product["SHEFFIELD"] && product["SHEFFIELD"] !== "" && product["HULL"] && product["HULL"] !== "") {
      product["Total Stock"] = parseInt(product["BOURNEMOUTH"]) + parseInt(product["SHEFFIELD"]) + parseInt(product["HULL"]);
    }
    else product["Total Stock"] = 0;
  });

  return { products, productCategories }
}

export const sortAlphabeticallyWithNonAlphaAtEnd = (object: any[], sortKey: string) => {
  return object.sort((a: any, b: any) => {
    const regex = /^[a-zA-Z]/;

    const aValue = a[sortKey] as unknown as string;
    const bValue = b[sortKey] as unknown as string;

    const aStartsWithLetter = regex.test(aValue);
    const bStartsWithLetter = regex.test(bValue);

    if (aStartsWithLetter && !bStartsWithLetter) {
      return -1; // a comes before b
    } else if (!aStartsWithLetter && bStartsWithLetter) {
      return 1; // b comes before a
    } else {
      return aValue.localeCompare(bValue);
    }
  });
};

export const formatNumber = (number: number) => {
  if (number > 1000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  }
  if (number > 1000) {
    return `${(number / 1000).toFixed(0)}K`;
  }
  return number.toLocaleString();
}