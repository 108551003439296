import React, { useEffect, useState } from 'react';

import { Supplier, Customer } from '../types';
import SearchBar from './searchBar';
import Pagination from './pagination';
import ModalWithChildren from './modalWithChildren';

import { listSuppliers, searchSuppliers, listCustomers, searchCustomers } from '../api';

type ProductModalProps = {
  setData: React.Dispatch<React.SetStateAction<null | any>>;
  modalIsOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataSource: "Suppliers" | "Customers";
};

const SuppliersAndCustomersSearchModal = ({ setData, modalIsOpen, setModalOpen, dataSource }: ProductModalProps) => {
  const [fetching, setFetching] = useState(true);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState([] as any[]);
  const [modalData, setModalData] = useState([] as Supplier[] | Customer[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  useEffect(() => {
    const fetchData = async () => {
      if (dataSource === "Customers") {
        try {
          const customers = await listCustomers();
          console.log(customers);
          let validCustomers = customers.filter((customer: any) => customer["NAME"] !== null);
          setModalData(validCustomers);
          setFilteredAndSearchedData(validCustomers);
        } catch (error) {
          console.error('There was a problem fetching customers:', error);
        }
      } else {
        try {
          const suppliers = await listSuppliers();
          let validSuppliers = suppliers.filter((supplier: any) => supplier["NAME"] !== null);
          setModalData(validSuppliers);
          setFilteredAndSearchedData(validSuppliers);
        } catch (error) {
          console.error('There was a problem fetching suppliers:', error);
        }
      }
      setFetching(false);
    };

    fetchData();
  }, [dataSource]);

  return (
    <ModalWithChildren modalOpen={modalIsOpen} setModalOpen={setModalOpen}>
      <div className="flex flex-col flex-grow gap-4">
        <SearchBar initialData={modalData} setResults={setFilteredAndSearchedData} searchFunction={searchSuppliers} childQuery='' />
        <div className="flex flex-col gap-4 dashboard-card bg-base-100 rounded-lg overflow-hidden">
          <div style={{maxHeight: "525px"}} className="overflow-y-scroll">
            <table className="table-auto w-full">
              <thead className="sticky top-0 bg-white border-b-2">
                <tr className="">
                  <th className="text-left pl-4 pt-4 text-sm">Supplier</th>
                  <th className="text-left pt-4 text-sm">ID</th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSearchedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((dataEntry: Supplier | Customer, rowIndex: number) => (
                  <tr 
                    key={rowIndex} 
                    onClick={() => {
                      console.log(dataEntry)
                      setData(dataEntry)
                      setModalOpen(false)
                    }} 
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td>
                      <div className="flex gap-4 items-center pr-2 hover:underline pl-4">
                        <p className="font-semibold text-sm">{dataEntry.NAME}</p>
                      </div>
                    </td>
                    <td>
                      <div className="flex gap-4 items-center pr-2 hover:underline">
                        <p className="font-semibold text-sm">{dataEntry["ACCNO"]}</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className="px-4 pb-4">
            <Pagination 
              numberOfEntries={modalData.length} 
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </div>
      </div>
    </ModalWithChildren>
  );
}

export default SuppliersAndCustomersSearchModal;