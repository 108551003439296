import React, { useState, useEffect, useRef } from 'react';
import { useScreen } from '../context';

import LightMetricCard from '../components/widgets/lightMetricCard';
import PrimaryTable from '../components/widgets/primaryTable';
import CustomersCard from '../components/customer';
import SuppliersCard from '../components/supplier';

import { businessAreas, maintenanceData, roles, users } from '../dummyData';
import { UserMaintenance } from './userMaintenance/userMaintenance';
import { RoleMaintenance } from './userMaintenance/roleMaintenance';
import PageTabs from '../components/pageTabs';
import { CustomerData, NonEmptyObject, User, UserOrderLimits, UserRole } from '../types';
import { ucFirst } from '../utils';
import { CollapsibleText } from '../components/widgets/collapsibleText';
import StatusBadge from '../components/statusBadge';
import SearchBar from '../components/searchBar';
import { ExportCSVButton } from '../components/widgets/exportCSVButton';

const UsersAndRolesPage = () => {
  const tabOptions = ["Roles", "Users"];
  const [tab, setTab] = useState(tabOptions[0]);

  const [maintenanceCardOpen, setMaintenanceCardOpen] = useState(false);
  const searchTerm = useRef<string>("");
  const [clickedUserId, setClickedUserId] = useState('');
  const [clickedRoleId, setClickedRoleId] = useState('');

  const dataPerTab: NonEmptyObject = {
    "Roles": roles,
    "Users": users
  }

  function clicked(value?: string) {
    if (tab === 'Users' && value) setClickedUserId(value);
    if (tab === 'Roles' && value) setClickedRoleId(value);
    setMaintenanceCardOpen(true)
  }

  function formatOrderLimits(orderLimits: UserOrderLimits) {
    const stringified = Object.entries(orderLimits).map(([orderLimitName, limit]) => 
      limit ? `${ucFirst(orderLimitName)}: ${limit}` : ''
    ).filter((value) => !!value).join(', ');
    return <CollapsibleText text={!!stringified ? stringified : "No limits applied"} maxLength={35} expandedByDefault={false} />
  }

  function createNew() {
    if (tab === 'Users') setClickedUserId('');
    if (tab === 'Roles') setClickedRoleId('');
    setMaintenanceCardOpen(true);
  }

  const roleHeaders = () => 
    <tr>
      <th className='text-left'>Name</th>
      <th className='text-left'>Business Area</th>
      <th className='text-left'>Role Id</th>
      <th className='text-left'>Description</th>
    </tr>
  
  const roleRow = (role: UserRole) => 
    <tr className='h-14 hover:bg-gray-100'>
      <td onClick={() => clicked(role.userRoleId)} className='hover:cursor-pointer'>{role.name}</td>
      <td onClick={() => clicked(role.userRoleId)} className='hover:cursor-pointer'>{role.businessArea}</td>
      <td onClick={() => clicked(role.userRoleId)} className='hover:cursor-pointer'>{role.userRoleId}</td>
      <td onClick={() => clicked(role.userRoleId)} className='hover:cursor-pointer'><CollapsibleText text={role.description} maxLength={150} expandedByDefault={false}/></td>
    </tr>

  const userHeaders = () => 
    <tr>
      <th className='w-12'></th>
      <th className='text-left'>Username</th>
      <th className='text-left'>Role</th>
      <th className='text-left'>User ID</th>
      <th className='w-16 text-left'>Email</th>
      <th className='text-left'>Order Limits</th>
      <th className='text-left'>Pronouns</th>
      <th>Super User?</th>
    </tr>

  const userRow = (user: User) =>
    <tr className='h-14 hover:bg-gray-100'>
      <td onClick={() => clicked(user.userId)} className='hover:cursor-pointer'><img className='w-10 h-10 rounded-md' src={user.profileImage} alt={user.profileImage ?? "Pfp"}/></td>
      <td onClick={() => clicked(user.userId)} className='hover:cursor-pointer'>{user.username}</td>
      <td onClick={() => clicked(user.userId)} className='hover:cursor-pointer'>{user.role.name}</td>
      <td>{user.userId}</td>
      <td className='pr-2'><CollapsibleText text={user.userEmailAddress ?? ""} maxLength={35} expandedByDefault={false} /></td>
      <td>{formatOrderLimits(user.orderLimits)}</td>
      <td>{user.pronouns}</td>
      <td className='text-center'><input type="checkbox" checked={user.isSuperUser} disabled></input></td>
    </tr>

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("USERS & ROLES");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="grid md:grid-cols-4 gap-4">
        <LightMetricCard title="Customers" count={1345} icon="user-tag" chartColor="blue" />
        <LightMetricCard title="Active Customers" count={14} icon="user-tie-hair" chartColor="red" />
        <LightMetricCard title="Suppliers" count={3820} icon="user-pen" chartColor="yellow" />
        <LightMetricCard title="Active Suppliers" count={4690} icon="user-crown" chartColor="green" />
      </div>

      {maintenanceCardOpen 
        ? <div className="flex-grow overflow-hidden">
            {tab === "Roles" && 
              <RoleMaintenance userRoleId={clickedRoleId} businessAreas={businessAreas} closeCard={() => setMaintenanceCardOpen(false)}/>
            }
          </div>
          :
          <div className='flex flex-col gap-4'>
            <div className='flex flex-row gap-4'>
              <button 
                className="btn-primary pr-2 pl-2 btn h-full mt-0.5 mb-0" 
                onClick={() => createNew()}
              >
                <i className="fa-solid fa-plus mr-1"></i>New
              </button>

              <PageTabs tabs={tabOptions} activeTab={tab} setActiveTab={setTab} card={true}/>



              <button className="dashboard-card bg-base-100 flex items-center gap-1 p-2 px-3 h-[50px] text-[#667085] ml-auto">
                <i className="fa-solid fa-filter-list"></i><span className="hidden md:block">&nbsp;&nbsp;Filters</span>
              </button>

              <ExportCSVButton dataExporting={dataPerTab[tab] ?? maintenanceData} fieldsToIgnore={[]} outputFileName={'Maintenance' + (searchTerm.current ? `-${searchTerm.current}` : '')}/>
            </div>
            <div className='dashboard-card p-4'>
              <table className="divide-y divide-gray-200 w-full">
                <thead>
                  {tab === "Roles" && roleHeaders()}
                  {tab === "Users" && userHeaders()}
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {tab === "Roles" && roles.map((role) => roleRow(role))}
                  {tab === "Users" && users.map((user) => userRow(user))}
                </tbody>
              </table>
            </div>
          </div>
      }
    </div>
  );
}

export default UsersAndRolesPage;