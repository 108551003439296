import { useRef, useState } from "react"
import { PermissionHierarchy, TriState, UserPermissions, UserRole } from "../types"
import { ToggleSwitch } from "./widgets/toggleSwitch"
import { permissionsHierarchy } from "../dummyData"
import Tooltip from "./widgets/tooltip"

type UserPermissionCustomisationProps = {
    userPermissions?: UserPermissions,
    propToHumanReadable: {[prop in keyof UserPermissions as string]: string} ,
    onAccessLevelToggled?: (prop: string, newValue: TriState) => void,
    isTriState?: boolean
}

type PermissionComponentProps = {
    permissions: UserPermissions, 
    prop: string,
    onToggle?: (newToggle: TriState) => void
}

type PermissionsHierarchyComponentProps = {
    permissions: UserPermissions, 
    hierarchy: PermissionHierarchy, 
    parentProp: string, 
    depth: number,
    key?: React.Key
}

const maxPermsDepth = 5;

//IMPORTANT: This control acts on the prinicple of Denied by Default.
export const UserPermissionCustomisation = ({userPermissions, propToHumanReadable, onAccessLevelToggled, isTriState = false}: UserPermissionCustomisationProps) => {
    const PermissionComponent = ({permissions, prop, onToggle} : PermissionComponentProps) => {
        const switchRef = useRef<HTMLButtonElement>(null);
        function toggled(newState: TriState) {
            if (onToggle) onToggle(newState);
            if (onAccessLevelToggled) onAccessLevelToggled(prop, newState);
        }

        return (
            <div className="w-48 flex flex-row" key={prop}>
                <label onClick={() => {switchRef.current?.click()}} className="w-40 md:w-48 font-semibold">{propToHumanReadable[prop] ?? prop}</label>
                <ToggleSwitch ref={switchRef} initialState={(isTriState ? permissions[prop] : (permissions[prop] ?? false))} onToggled={toggled} isTriState={isTriState} toggledMiddleTooltip="Inherit from role"/> 
            </div>
        );
    }

    //Used so we can have nesting. parentProp is the prop that is the parent to all of the sub permissions.
    const PermissionsHierarchyComponent = ({permissions, hierarchy, parentProp, depth, key}: PermissionsHierarchyComponentProps) => {
        const [showChildren, setShowChildren] = useState(parentProp === "" || permissions[parentProp] || (isTriState && permissions[parentProp] === undefined));
        const [childrenManuallyHiddenByUser, setChildrenManuallyHiddenByUser] = useState(false);

        function isEmptyHierarchy(hierarchyChecking: PermissionHierarchy) {
            const empty = !hierarchyChecking || Object.keys(hierarchyChecking)?.length === 0;
            return empty
        }

        function toggleParentPermission(newState: TriState) {
            const show = newState || newState === undefined;
            setShowChildren(show);
            permissions[parentProp] = newState;
            //Disable sub permissions since they can't be granted if the parent one is denied
            //Remember those somehow? Or should sub permission disabling be determined elsewhere?
        }

        if (depth === 0) {
            //Iterate over all of the first layer of permissions and generate a PermissionsHierarchyComponent for each
            return (
                <div className="flex flex-col gap-4">
                    {
                        Object.keys(hierarchy).map((prop) => 
                            <PermissionsHierarchyComponent 
                                permissions={permissions} 
                                hierarchy={hierarchy} 
                                parentProp={prop} 
                                depth={depth + 1} 
                                key={prop}
                            />
                        )
                    }
                </div>
            );
        }

        //Recursion can be dangerous so make sure we limit it.
        if (depth >= maxPermsDepth /* or if the children are empty */) {
            return <PermissionComponent permissions={permissions} prop={parentProp}/>
        }

        return (
            <div className="flex flex-col" key={key}>
                <div className="flex flex-row">
                    <PermissionComponent permissions={permissions} prop={parentProp} onToggle={toggleParentPermission}/>
                    {showChildren && !isEmptyHierarchy(hierarchy[parentProp]) && 
                        <Tooltip content={`${childrenManuallyHiddenByUser ? "Show" : "Hide"} Children`}>
                            <i 
                                className={`ml-4 rounded-xl bg-[#3A5BFF26] p-1 fa-solid ${childrenManuallyHiddenByUser ? 'fa-plus' : 'fa-minus'} hover:cursor-pointer`} 
                                onClick={() => setChildrenManuallyHiddenByUser(!childrenManuallyHiddenByUser)}
                            ></i>
                        </Tooltip>
                    }
                </div>
                {/* Only render children if the parent is enabled so we're not rendering unecessary HTML. For manual hiding, use CSS to preserve hidden/shown status of children */}
                {showChildren && !isEmptyHierarchy(hierarchy[parentProp]) &&
                    <div className={`ml-10 flex flex-col gap-4 mt-4 ${childrenManuallyHiddenByUser ? 'hidden' : ''}`}>
                        {
                            Object.keys(hierarchy[parentProp] ?? {}).map((childProp: string) => 
                                <PermissionsHierarchyComponent 
                                    permissions={permissions} 
                                    hierarchy={hierarchy[parentProp]} 
                                    parentProp={childProp} 
                                    depth={depth + 1} 
                                    key={childProp}
                                />
                            )
                        }
                    </div>
                }
            </div>
        );
    }

    return (
        <>
        {userPermissions &&
            <PermissionsHierarchyComponent permissions={userPermissions} hierarchy={permissionsHierarchy} parentProp={""} depth={0}/>
        }
        </>
    )
}