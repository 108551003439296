import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackLink = () => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)}>
      <i className="fas fa-arrow-left mr-2"></i>Back
    </button>
  );
}

export default BackLink;