import { useEffect, useRef, useState } from "react";
import VerticalTextInput from "../../components/verticalTextInput"
import { TriState, User, UserPermissions, UserRole } from "../../types";
import { roles as dummyRoles, propToHumanReadable } from "../../dummyData";
import { UserPermissionCustomisation } from "../../components/userPermissionCustomisation";


type RoleMaintenanceProps = {
    userRoleId: string,
    businessAreas: string[],
    closeCard: () => void
}

const roles: UserRole[] = dummyRoles;


export const RoleMaintenance = ({userRoleId, businessAreas, closeCard}: RoleMaintenanceProps) => {
    const [roleId, setRoleId] = useState<string>(userRoleId);
    const [roleName, setRoleName] = useState<string>("");
    const [businessArea, setBusinessArea] = useState<string>("");
    const [selectedAccessLevels, setSelectedAccessLevels] = useState<UserPermissions>({} as UserPermissions)
    const [description, setDescription] = useState<string>('')
    const [creatingNewUser, setCreatingNewUser] = useState<boolean>(false);
    //Not updated until save is pressed.
    const chosenRole = useRef<UserRole>({
        userRoleId: "",
        name: "",
        businessArea: "",
        permissions: {} as UserPermissions,
        description: ""
    });

    function saveUserChanges() {
        chosenRole.current.name = roleName;
        chosenRole.current.businessArea = businessArea;
        chosenRole.current.permissions = selectedAccessLevels;
        chosenRole.current.description = description;

        const foundExistingRole = roles.find((value) => value.userRoleId === chosenRole.current.userRoleId);

        if (userRoleId && foundExistingRole) {
            //Update role
            roles[roles.indexOf(foundExistingRole)] = chosenRole.current
        } else {
            //Create role
            chosenRole.current.userRoleId = roleId;
            roles.push(chosenRole.current);
        }

        closeCard();
    }

    function accessLevelToggled(prop: string, newValue: TriState) {
        //chosenRole.current.accessLevels = {...chosenRole.current.accessLevels, [prop]: newValue}
        if (chosenRole.current.permissions) setSelectedAccessLevels({...chosenRole.current.permissions, [prop]: newValue});
        else setSelectedAccessLevels({[prop]: newValue} as UserPermissions);
    }

    useEffect(() => {
        //Get role from userRoleId
        const searchedRole = roles.filter((value: UserRole) => userRoleId === value.userRoleId)[0];

        if (searchedRole) {
            chosenRole.current = searchedRole;
            setRoleName(chosenRole.current.name);
            setBusinessArea(chosenRole.current.businessArea);
            setDescription(chosenRole.current.description);
        } else {
            setCreatingNewUser(true);
        }
    }, [userRoleId])

    return (
        <section className="dashboard-card flex flex-col p-2">
            <header className="flex flex-row w-full">
                <div className="w-[95%]"><span className="font-bold text-2xl">Role Maintenance</span></div>
                <button onClick={() => {if (closeCard) closeCard()}}><i className="fa-solid fa-close text-red-600 border-2 rounded border-gray-300 pr-[6px] pl-[4px] text-xl hover:bg-gray-300"></i></button>
            </header>
            <div className="flex flex-col gap-2">
                <span className="font-bold">Overview</span>
                <VerticalTextInput 
                    label="Role Id" 
                    labelColourClass="text-black" 
                    labelClassName="text-lg" 
                    value={roleId}
                    labelFontClass="text-lg font-bold"
                    inputClassName={`border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base${!creatingNewUser && " bg-gray-100"}`}
                    className="m-0" 
                    onValueChanged={(newValue: string) => setRoleId(newValue)}
                    readonly={!creatingNewUser}
                />
                <VerticalTextInput 
                    label="Name" 
                    labelColourClass="text-black" 
                    labelClassName="text-lg" 
                    value={roleName}
                    labelFontClass="text-lg font-bold"
                    inputClassName="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base" 
                    className="m-0" 
                    onValueChanged={(newValue: string) => setRoleName(newValue)}
                />
                <span className="text-sm text-[#8181A5]">Name of Role that can be allocated to system users on a 1 to many relationship.</span>
                <VerticalTextInput
                    label="Description" 
                    labelColourClass="text-black" 
                    labelClassName="text-lg" 
                    value={description}
                    labelFontClass="text-lg font-bold"
                    inputClassName="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base" 
                    className="m-0" 
                    onValueChanged={(newValue: string) => setDescription(newValue)}
                    multiline
                />
                <span className="font-bold">Role Area</span>
                <select value={businessArea} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setBusinessArea(e.target.value)} className="border-2 rounded border-gray-300 w-96">
                    <option value="" disabled>Select a role area...</option>
                    {businessAreas.map((value) => <option value={value} key={value}>{value}</option>)}
                </select>
                <span className="text-sm text-[#8181A5]">Select business area of role for description purposes. Limit of 1 Business area per role.</span>
                <span className="font-bold">Access Level</span>
                <ul className="w-full flex flex-col gap-3">
                    <UserPermissionCustomisation userPermissions={chosenRole.current.permissions ?? {} as UserPermissions} propToHumanReadable={propToHumanReadable} onAccessLevelToggled={accessLevelToggled}/>
                </ul>
                <span className="text-sm text-[#8181A5] mb-2">These can be overriden on a per user basis in the user maintenance menu.</span>

                <button className="btn-secondary pt-2 pb-2 pl-4 pr-4 w-full lg:w-96 md:w-28">View Site as Role</button>

                <button 
                    className="btn-primary pt-2 pb-2 pl-4 pr-4 w-full lg:w-96 md:w-28" 
                    onClick={saveUserChanges}>
                        <i className="fa-solid fa-floppy-disk mr-1"></i>{creatingNewUser ? "Create" : "Save"}
                </button>
            </div>
        </section>
    );
}