import React, { useEffect, useState, useContext } from 'react';
import { useEcommerceContext } from './context';

import { handleProductSelect, isProductInBasket, removeItemFromBasket } from '../../components/basketUtils';

import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd } from "../../utils";
import { Product } from '../../types';

import EcommerceNav from './nav';

import { calculateSalesOrderBasketTotals, changeProductQuantity } from '../../components/basketUtils';

const CheckoutPage = () => {
  const { basket, setBasket } = useEcommerceContext();
  const [activeBasketItem, setActiveBasketItem] = useState("");
  useEffect(() => {
    console.log(basket, activeBasketItem)
  }, [basket, activeBasketItem]);

  const [orderNote, setOrderNote] = useState("");
  const [orderTotals, setOrderTotals] = useState({subtotal: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);

  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);

  useEffect(() => {
    const { subtotal } = calculateSalesOrderBasketTotals(basket);
    setOrderTotals({subtotal});

    const basketProductCodes = basket.map(item => item.product["PRODUCT.CODE"]);
    setFilteredAndSearchedProducts(
      productData.filter(
        (product: Product) => product["PICT.FLAG"] !== "0" && !basketProductCodes.includes(product["PRODUCT.CODE"])
      )
    );
  }, [basket, productData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();
        setProductData(products);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col flex-grow bg-white min-h-screen">
      <EcommerceNav />
        
      <div className="grid md:grid-cols-2 gap-10 md:gap-12">
        <div className="border-r border-gray-300 py-8 min-h-screen">
          <h2 className="font-bold text-2xl md:text-3xl md:ml-24">Your Basket</h2>
          <div className="mt-4">
            {basket.map((item, index) => (
            <div key={index} className="flex items-center gap-4 md:gap-6 border-b border-gray-300 py-4 md:pl-24 md:pr-12">
              <img src={item.product["PICT.FLAG"]} alt={item.product.DESCRIPTION} className="h-20" />
              <div className="flex-grow">
                <p className="font-bold">{item.product.DESCRIPTION}</p>
                <p>£{item.basketPrice}</p>
              </div>
              <div>
                <p className="font-bold">Quantity</p>
                <div className="flex justify-between gap-4 border border-black p-2 text-xl min-w-32 px-4">
                  <button
                    onClick={() => changeProductQuantity("decrease", item.product["PRODUCT.CODE"], basket, setBasket)}
                    disabled={item.quantity === 1}
                  >
                    -
                  </button>
                  <p>{item.quantity}</p>
                  <button 
                    onClick={() => changeProductQuantity("increase", item.product["PRODUCT.CODE"], basket, setBasket)}
                  >
                    +
                  </button>
                </div>
              </div>
              <button 
                className="font-bold"
                onClick={() => removeItemFromBasket(
                  item.product["PRODUCT.CODE"], 
                  basket, 
                  setBasket,
                  setActiveBasketItem,
                  activeBasketItem
                )}
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            </div>
            ))}
          </div>

          <div className="grid md:grid-cols-2 gap-6 md:gap-12 mt-6 md:pl-24 md:pr-12">
            <div className="">
              <p className="font-bold mb-4">Add Order Note</p>
              <textarea 
                className="border border-black p-2 w-full"
                placeholder="Order note..."
                rows={4}
                value={orderNote}
                onChange={(e) => setOrderNote(e.currentTarget.value)}
              ></textarea>
            </div>

            <div>
              <div className="flex justify-between items-center font-bold text-lg">
                <h3>Subtotal:</h3>
                <h3>£{orderTotals.subtotal}</h3>
              </div>
              <p className="text-gray-700">Shipping and taxes calculated at checkout</p>

              <button className="bg-black text-white p-2 mt-6 w-full">
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
        <div className="min-h-screen py-8 md:pr-24">
          <h2 className="font-bold text-2xl md:text-3xl md:mr-24 mb-8">We recommend these products...</h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-4 md:gap-x-8 gap-y-8">
            {sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, "DESCRIPTION")
              .sort((a: Product, b: Product) => {
                if (a["Total Stock"] > 0 && b["Total Stock"] === 0) return -1;
                if (a["Total Stock"] === 0 && b["Total Stock"] > 0) return 1;
                return 0;
              })
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((product: Product, rowIndex: number) => {
                let itemIsInBasket = isProductInBasket(product['PRODUCT.CODE'], basket);
                return (
                <div className="flex items-center flex-col gap-2">
                  <img src={product["PICT.FLAG"]} alt={product.DESCRIPTION} className="h-48 md:h-44 lg:h-60 w-auto rounded-md" />
                  <div className="flex gap-2 justify-between items-start">
                    <div>
                      <p className="font-semibold">{product.DESCRIPTION}</p>
                      <p className="text-lg">£{product["LIST.PRICE"]}</p>
                    </div>

                    <button 
                      className={`${itemIsInBasket ? "bg-red-400" : "bg-black"} text-white p-2 px-3`}
                      onClick={() => {
                        if (itemIsInBasket) {
                          removeItemFromBasket(
                            product['PRODUCT.CODE'],
                            basket,
                            setBasket,
                            setActiveBasketItem,
                            activeBasketItem
                          );
                        }
                        else {
                          handleProductSelect(
                            product['PRODUCT.CODE'], 
                            productData,
                            basket, 
                            setBasket, 
                            setActiveBasketItem, 
                            "sale"
                          );
                        }
                      }}
                    >
                      <i className={`fa-solid fa-basket-shopping-${itemIsInBasket ? "minus" : "plus"}`}></i>
                    </button>
                  </div>
                </div>
              )}
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPage;