import React, { useState, useEffect, useCallback } from 'react';
import { useScreen } from '../context';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Product } from '../types';
import BackButton from '../components/backLink';

import { fetchProduct } from '../api';

const ProductCard = () => {
  // Get product Id and back value from URL which will be /products/:id?back=/
  const { id: productId } = useParams<{ id: string }>();
  const tabOptions = ["Overview", "Documents", "FIFO", "Stock Level Management"];
  const [tab, setTab] = useState(tabOptions[0]);
  const creatingNewProduct = productId === "newProd";
  const [fetchingData, setFetchingData] = useState(creatingNewProduct ? false : true);
  const [productData, setProductData] = useState({
    "PICT.FLAG": "",
    "PRODUCT.CODE": "",
    Supp: "",
    "PROD.GROUP": "",
    "LIST.PRICE": 0,
    "COST.PRICE": 0,
    DESCRIPTION: "",
    "Supplier's pc": "",
    "SUPPLIER": "",
    "BOURNEMOUTH": "0",
    "SHEFFIELD": "0",
    "HULL": "0",
    "Total Stock": 0
  } as Product);
  const [originalProductData, setOriginalProductData] = useState({} as Product);
  const [showExhaustedBands, setShowExhaustedBands] = useState(false);
  const { setScreenId, setPageHasDirtyForm } = useScreen();
  setScreenId(`PRODUCT ${productId}`);

  useEffect(() => {
    if (!productId) return;

    const fetchData = async () => {
      try {
        const data = await fetchProduct(productId);
        //console.log(data);
        setProductData(data[0]);
        setFetchingData(false);
      } catch (error) {
        setFetchingData(false);
        console.error('There was a problem fetching accounts:', error);
      }
    };

    fetchData();
    //deleteLocalProducts();
  }, [productId, creatingNewProduct]);

  const addNewProduct = () => {
    // Retrieve any previously stored products from local storage
    const storedItems = JSON.parse(
      localStorage.getItem("localStorageProducts") || "[]"
    );
  
    // Add the new product to the list
    const updatedItems = [...storedItems, productData];
  
    // Store the updated list back in local storage
    localStorage.setItem("localStorageProducts", JSON.stringify(updatedItems));
    console.log("Product added to local storage");
  };

  const inputField = (label: string, productDataKey: keyof Product) => (<>
    <div className="label">{label}</div>
    <input 
      type="text" 
      className="input" 
      placeholder={`${label}...`} 
      value={productData[productDataKey] as string | number}
      onChange={(e) => {
        setPageHasDirtyForm(true);
        setProductData({...productData, [productDataKey]: e.target.value})
      }}
    />
  </>);

  return (
    <div className="dashboard-card bg-base-100 flex-grow p-4 flex flex-col">
      
      {fetchingData &&  (
        <div className="flex flex-col justify-center items-center flex-grow">
          <i className="fa-solid fa-spinner fa-spin text-4xl"></i>
          <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
        </div>
      )}

      {!fetchingData && (
      
        (productData || creatingNewProduct)
        ? <>
            <div className="flex items-start gap-4">
              {!creatingNewProduct && <img src={productData["PICT.FLAG"]} alt={productData.DESCRIPTION} className="w-12 h-12 md:w-24 md:h-24 rounded-md" />}
              <div>
                {creatingNewProduct && <h3 className="uppercase text-sm text-center md:text-left">Add New Product</h3>}
                <h3 className="font-semibold text-xl text-center md:text-left mt-auto mb-3">{productData.DESCRIPTION}</h3>
              </div>
            </div>

            <BackButton />

            <div className="hidden md:flex mt-4">
              {tabOptions.map((option, index) => (
                <button key={index} className={`px-2 pb-2 border-b-2 ${tab === option ? 'border-[#1366D9]' : 'border-[#F0F1F3]'} hover:border-[#1366D9]`} onClick={() => setTab(option)}>{option}</button>
              ))}
              <div className="flex-grow border-b-2 border-[#F0F1F3]" />
            </div>

            <select className="block md:hidden mt-4 pb-2 border-b-2 border-[#F0F1F3] px-2" onChange={(e) => setTab(e.target.value)}>
              {tabOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>

            {tab === "Overview" && (
              <div className="flex w-100 gap-4 lg:gap-6 flex-grow flex-col xl:flex-row">
                <div className="flex-grow p-4">
                  <h3 className="font-semibold">Primary Details</h3>

                  
                    <div className="form-container mt-4">
                      {inputField("Product name", "DESCRIPTION")}
                      {inputField("Product ID", "PRODUCT.CODE")}
                      <div className="label">Product Category</div>
                      <select 
                        className="input" 
                        value={productData["PROD.GROUP"]} 
                        onChange={(e) => setProductData({...productData, "PROD.GROUP": e.target.value})}
                      >

                      </select>
                      {inputField("FIFO Cost", "COST.PRICE")}

                      <h3 className="font-semibold">Supplier Details</h3><div />

                      {inputField("Supplier name", "SUPPLIER")}
                      {inputField("Contact Number", "Supplier's pc")}
                    </div>
                </div>

                <div className="flex flex-col items-center shrink-0 md:pt-6">
                  <div className="flex w-full gap-1 justify-center md:justify-end mt-auto">
                    {creatingNewProduct
                      ? <button className="btn btn-primary" onClick={addNewProduct}><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Add Product</button>
                      : <button className="btn btn-outline"><i className="fa-solid fa-pencil"></i>&nbsp;&nbsp;Save</button>
                    }
                  </div>
                </div>
              </div>
            )}

            {tab === "Documents" && (
              <div className="flex w-100 gap-4 lg:gap-6 flex-grow flex-col xl:flex-row">
                <div className="flex-grow p-4">
                  <h3 className="font-semibold mb-4">Manage Product Documents</h3>

                  {["Warranty", "Installer Instructions", "Product Sheet"].map((label, index) => (
                    <div key={index} className="flex gap-4 items-center p-4 mb-4 bg-gray-100 border-gray-400 rounded-md cursor-pointer max-w-[600px]" onClick={() => {
                      const fileInput = document.getElementById(`file-upload-${index}`);
                      if (fileInput) fileInput.click();
                    }}>
                      <i className="fa-solid fa-file-upload text-gray-600 text-2xl"></i>
                      <div className="flex-grow">
                        <div className="font-semibold text-gray-700">{label}</div>
                        <div className="text-gray-500">Select a file to upload</div>
                      </div>
                      <input id={`file-upload-${index}`} type="file" className="hidden" />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {tab === "FIFO" && (
              <div className="flex w-100 gap-4 lg:gap-6 flex-grow flex-col xl:flex-row">
                <div className="flex-grow p-4">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="font-semibold">FIFO Stock Levels</h3>
                      <button className="btn btn-outline" onClick={() => setShowExhaustedBands(!showExhaustedBands)}>
                      {showExhaustedBands ? 'View Current FIFO Table' : 'View Exhausted FIFO Bands'}
                      </button>
                    </div>

                    {showExhaustedBands ? (
                      <table className="table-auto w-full mt-4">
                      <thead>
                      <tr>
                      <th className="px-4 text-left py-2">Date</th>
                      <th className="px-4 py-2">Type</th>
                      <th className="px-4 py-2">Customer/Supplier</th>
                      <th className="px-4 py-2">Stock Movement</th>
                      <th className="px-4 py-2">FIFO Net Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                        {[
                        { date: '2023-09-21', type: 'Sale', customerSupplier: 'Customer X', stockMovement: -50, balance: 0, price: '$145' },
                        { date: '2023-09-20', type: 'Sale', customerSupplier: 'Customer Y', stockMovement: -30, balance: 0, price: '$150' },
                        { date: '2023-09-19', type: 'Stock Adjustment', customerSupplier: 'Warehouse', stockMovement: -20, balance: 0, price: '$155' },
                        { date: '2023-09-18', type: 'Sale', customerSupplier: 'Customer Z', stockMovement: -10, balance: 0, price: '$160' },
                        { date: '2023-09-17', type: 'Sale', customerSupplier: 'Customer W', stockMovement: -40, balance: 0, price: '$165' },
                        { date: '2023-09-16', type: 'Sale', customerSupplier: 'Customer V', stockMovement: -60, balance: 0, price: '$170' },
                        { date: '2023-09-15', type: 'Sale', customerSupplier: 'Customer U', stockMovement: -70, balance: 0, price: '$145' },
                        { date: '2023-09-14', type: 'Sale', customerSupplier: 'Customer T', stockMovement: -80, balance: 0, price: '$150' },
                        { date: '2023-09-13', type: 'Sale', customerSupplier: 'Customer S', stockMovement: -90, balance: 0, price: '$155' },
                        { date: '2023-09-12', type: 'Sale', customerSupplier: 'Customer R', stockMovement: -100, balance: 0, price: '$160' }
                        ].map((entry, index) => (
                        <tr key={index}>
                        <td className="px-4 py-2">{entry.date}</td>
                        <td className="px-4 text-center py-2">{entry.type}</td>
                        <td className="px-4 text-center py-2">{entry.customerSupplier}</td>
                        <td className="px-4 text-center py-2">{entry.stockMovement > 0 ? `+${entry.stockMovement}` : entry.stockMovement}</td>
                        <td className="px-4 text-center py-2">{entry.price}</td>
                        </tr>
                        ))}
                      </tbody>
                      </table>
                    ) : (
                      <table className="table-auto w-full mt-4">
                      <thead>
                      <tr>
                      <th className="px-4 text-left py-2">Date</th>
                      <th className="px-4 py-2">Type</th>
                      <th className="px-4 py-2">Customer/Supplier</th>
                      <th className="px-4 py-2">Stock Movement</th>
                      <th className="px-4 py-2">FIFO Net Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                      {[
                      { date: '2023-10-01', type: 'Purchase', customerSupplier: 'Supplier A', stockMovement: 100, balance: 100, price: '$150' },
                      { date: '2023-10-02', type: 'Sale', customerSupplier: 'Customer B', stockMovement: -20, balance: 80, price: '$162' },
                      { date: '2023-10-03', type: 'Stock Adjustment', customerSupplier: 'Warehouse', stockMovement: 10, balance: 90, price: '$160' }
                      ].reverse().map((entry, index) => (
                      <tr key={index}>
                        <td className="px-4 py-2">{entry.date}</td>
                        <td className="px-4 text-center py-2">{entry.type}</td>
                        <td className="px-4 text-center py-2">{entry.customerSupplier}</td>
                        <td className="px-4 text-center py-2">{entry.stockMovement > 0 ? `+${entry.stockMovement}` : entry.stockMovement}</td>
                        <td className="px-4 text-center py-2">{entry.price}</td>
                      </tr>
                      ))}
                      </tbody>
                      </table>
                    )}
                </div>
              </div>
            )}
          </>
        :  <div className="flex flex-col justify-center items-center flex-grow">
            <i className="fa-sharp fa-solid fa-circle-exclamation text-4xl"></i>
            <h3 className="font-semibold text-lg md:text-2xl ml-2">Couldn't fetch data...</h3>
          </div>
      )}
    </div>
  );
}

export default ProductCard;