import React, { useState, useEffect, act } from 'react';
import { useParams } from 'react-router-dom';
import { useKeyPress } from "../utils";
import { useScreen } from '../context';

import { eqsiData } from '../dummyData';
import { BasketItem, Customer } from '../types';

import { fetchSalesOrder, fetchBulkProducts } from '../api';
import SuppliersAndCustomersSearchModal from './suppliersAndCustomersSearchModal';

import { 
  handleGPupdate, 
  handleGPchange,
  calculateSalesOrderBasketTotals, 
  removeItemFromBasket,
  changeProductQuantity
} from './basketUtils';

import ProductsSearchModal from './productsSearchModal';

const SalesOrder = () => {
  const { id: salesOrderId } = useParams<{ id: string }>();

  const [basket, setBasket] = useState([] as BasketItem[]);

  const [deliveryLocation, setDeliveryLocation] = useState("");
  const branches = ["Customer Location A", "Customer Location B", "Customer Location C"];

  const [customerData, setCustomerData] = useState(null as null | Customer);
  const [basketStats, setBasketStats] = useState({ subtotal: 0, profit: 0, grossProfit: 0 });
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [activeBasketItem, setActiveBasketItem] = useState(null as null | string);
  const [orderStatus, setOrderStatus] = useState("Unsaved");
  const [customersModalOpen, setCustomersModalOpen] = useState(false);
  const [productsModalOpen, setProductsModalOpen] = useState(false);

  const [orderData, setOrderData] = useState([] as any[]);

  const { setScreenId, setPageHasDirtyForm } = useScreen();
  setScreenId(`SO-${salesOrderId}`)
  
  const clearEQSI = () => {
    setCustomerData(null);
    setBasket([]);
    setBasketStats({ subtotal: 0, profit: 0, grossProfit: 0 });
    setActiveBasketItem(null);
  }

  const backAction = () => {;
  }
  useKeyPress('F1', backAction);

  const upAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item: BasketItem) => item.product["PRODUCT.CODE"] === activeBasketItem);
      if (indexOfItem !== 0) setActiveBasketItem(basket[indexOfItem - 1].product["PRODUCT.CODE"]);
    }
  }
  useKeyPress('ArrowUp', upAction);

  const downAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item: BasketItem) => item.product["PRODUCT.CODE"] === activeBasketItem);
      if (indexOfItem !== basket.length - 1) setActiveBasketItem(basket[indexOfItem + 1].product["PRODUCT.CODE"]);
    }
  }
  useKeyPress('ArrowDown', downAction);

  useEffect(() => {
    async function fetchSalesOrderData() {
      if (salesOrderId === "NEW" || !salesOrderId) return;
      let fetchedOrderData = await fetchSalesOrder(salesOrderId);
      let order = fetchedOrderData[0]
      setOrderData(order)
      console.log(order);

      if (order["PRODUCT.CODE"] && order["PRODUCT.CODE"].length > 0) {
        let products = await fetchBulkProducts(order["PRODUCT.CODE"]);
        let basketData = order["PRODUCT.CODE"].map((productCode: string, index: number) => {
          products[index]["Total Stock"] = parseInt(products[index]["BOURNEMOUTH"]) + parseInt(products[index]["HULL"]) + parseInt(products[index]["SHEFFIELD"]);
          return {
            product: products[index],
            quantity: parseInt(order["Order Quant."][index]),
            basketPrice: parseFloat(order["Price"][index]),
            tax: 0
          }
        });

        console.log(basketData)
        setBasket(basketData);
        console.log(products);
      }
    }

    fetchSalesOrderData();
  }, [salesOrderId]);


  useEffect(() => {
    console.log(filteredAndSearchedProducts);
  }, [filteredAndSearchedProducts]);

  useEffect(() => {
    if (basket.length == 0) {
      setBasketStats({ subtotal: 0, profit: 0, grossProfit: 0 });
      return
    }

    setPageHasDirtyForm(true);
    setBasketStats(calculateSalesOrderBasketTotals(basket));
  }, [basket]);
  

  return (
    <div className="flex flex-col gap-6 items-start w-full">
      <ProductsSearchModal 
        basket={basket}
        setBasket={setBasket}
        setActiveBasketItem={setActiveBasketItem}
        activeBasketItem={activeBasketItem}
        modalIsOpen={productsModalOpen}
        setModalOpen={setProductsModalOpen}
        screen="sale"
      />

      <SuppliersAndCustomersSearchModal 
        modalIsOpen={customersModalOpen}
        setModalOpen={setCustomersModalOpen}
        setData={setCustomerData}
        dataSource="Customers"
      />


      <div className="flex w-full items-end gap-4 dashboard-card p-4">
        {salesOrderId === "NEW"
          ? <>
              <h2 className="font-semibold text-2xl">New Sales Order</h2>

              <button
                className="btn btn-secondary ml-auto"
                onClick={() => {
                  clearEQSI();
                  window.location.href = "/purchasing";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-green"
              >
                Save as Quote
              </button>
            </>
          : <>
              <div>
                <p className="uppercase font-semibold text-sm">Sales Order</p>
                <h2 className="text-widget-green font-bold text-2xl">{salesOrderId}</h2>
              </div>

              <button
                className="btn btn-secondary ml-auto"
                onClick={() => {
                  clearEQSI();
                  window.location.href = "/eqsi";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-green"
              >
                Save as Draft
              </button>
            </>
        }
      </div>

      <div className="grid md:grid-cols-3 gap-6 w-full">
        <div className="dashboard-card p-4 flex gap-4 items-end">
          <div className="h-20 w-20 bg-widget-green text-widget-green rounded-md flex items-center justify-center">
            {customerData 
              ? <img
                  src={`/supplierImages/givenergy.png`}
                  alt={customerData.NAME}
                  className="w-full h-full object-cover rounded-md"
                />
              : <i className="fa-solid fa-user text-4xl"></i>
            }
          </div>

          <div>
            <h3 className="font-semibold uppercase text-sm">Customer</h3>
            <div className="flex gap-2 items-center cursor-pointer" onClick={() => setCustomersModalOpen(true)}>
              {customerData
                ? <h2 className="font-light text-xl md:text-2xl">{customerData.NAME}</h2>
                : <h2 className="font-semibold text-xl">Select a customer</h2>
              }
              <i className="fas fa-chevron-down"></i>
            </div>
            {customerData && <h3 className="font-light text-lg">55 Park Lane, London, SW11 3GS</h3>}
          </div>
        </div>

        <div className="dashboard-card p-4 flex gap-4 items-end">
          <div className="bg-widget-green text-widget-green h-20 w-20 flex items-center justify-center rounded-md">
            <i className="fas fa-map-location text-4xl"></i>
          </div>

          <div className="flex-grow">
            <h3 className="font-semibold uppercase text-sm">Delivery Location</h3>
            <select 
              className="input text-xl text-light outline-none"
              value={deliveryLocation}
              onChange={(e) => setDeliveryLocation(e.target.value)}
            >
              <option value="" disabled>Select a branch</option>
              {branches.map((branch: string, index: number) => (
                <option key={index} value={branch}>{branch}</option>
              ))}
            </select>
            {deliveryLocation && 
              <div className="flex justify-between items-center gap-2">
                <h3 className="font-light">123 Any Street, {deliveryLocation.replace("Branch", "")}</h3>
              </div>
            }
          </div>
        </div>

        <div className="dashboard-card p-4 flex gap-4 items-end">
          <div className="bg-widget-green text-widget-green h-20 w-20 flex items-center justify-center rounded-md">
            <i className="fas fa-shopping-basket text-4xl"></i>
          </div>

          <div className="">
            <h3 className="font-semibold uppercase text-sm">Order Totals</h3>
            <h2 className="font-semibold text-xl">Subtotal: £{basketStats.subtotal.toFixed(2)}</h2>
            <h3 className="font-light text-lg">Profit: £{basketStats.profit.toFixed(2)} ({basketStats.grossProfit.toFixed(2)}%)</h3>
          </div>
        </div>
      </div>

      <button 
        className="btn btn-green w-full flex items-center justify-center uppercase font-extrabold hover:bg-black"
        onClick={() => setProductsModalOpen(true)}
      >
        <i className="fas fa-plus mr-2"></i>
        Add Product
      </button>
      
      {basket && basket.length > 0 && (
        <div className="dashboard-card bg-base-100 w-full pt-4">
          <table className="w-full text-left">
            <thead>
              <tr className="text-sm uppercase">
                <th className="pl-8 pb-2">Product</th>
                <th className="text-center pb-2">Stock (Incoming)</th>
                <th className="text-center pb-2">Cost Price</th>
                <th className="text-center pb-2">Terms Discount</th>
                <th className="text-center pb-2">Tax</th>
                <th className="text-center pb-2">Line Val</th>
                <th className="text-center pb-2 pr-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {basket.map((item: BasketItem, index: number) => (
                <tr 
                  key={index} 
                  className={`cursor-pointer border-t border-gray-200`}
                  onClick={() => setActiveBasketItem(item.product["PRODUCT.CODE"])}
                >
                  <td className="flex items-end gap-4 pl-6 py-4">
                    <img src={item.product["PICT.FLAG"]} alt={item.product["PRODUCT.CODE"]} className="h-16 w-16 object-cover rounded-md border border-gray-200 rounded-md" />
                    <div className="pb-1">
                      <p className="text-sm font-semibold">{item.product.DESCRIPTION}</p>
                      <div className="flex gap-2 items-center justify-start mt-1">
                        <div className="flex rounded border border-gray-300 bg-white items-center justify-start">
                          <div className="border-gray-300 border-r px-1">
                            <p className="text-sm">£</p>
                          </div>
                          <div className="border-gray-300 border-r px-1">
                            <input 
                              type="number" 
                              value={item.basketPrice} 
                              style={{ width: `${item.basketPrice.toString().length * 9 + 25}px` }}
                              onChange={(e) => {
                                const updatedBasket = [...basket];
                                updatedBasket[index].basketPrice = parseFloat(e.target.value);
                                setBasket(updatedBasket);
                              }}
                              className="bg-transparent text-sm"
                            />
                          </div>
                          <div className="border-gray-300 border-r px-1">
                            <p className="text-sm">GP %</p>
                          </div>
                          <div className="border-gray-300 pl-1">
                            <input 
                              type="number"
                              id={`${item.product["PRODUCT.CODE"]}-GP`}
                              value={item.gp ? parseFloat(item.gp.toFixed(2)) : 0}
                              onChange={(e) => handleGPupdate(e, index, basket, setBasket)}
                              onKeyDown={(e) => handleGPchange(e, index, basket, setActiveBasketItem)}
                              className="bg-transparent text-sm w-10"
                            />
                          </div>
                        </div>

                        <div className="flex rounded-2xl px-1 border border-gray-300 bg-white items-center justify-start">
                          <button
                            onClick={() => changeProductQuantity("decrease", item.product["PRODUCT.CODE"], basket, setBasket)}
                            disabled={item.quantity === 1}
                            className="border-gray-300 border-r px-1"
                          >
                            -
                          </button>
                          <input
                            type="number"
                            value={item.quantity || undefined}
                            className="px-1 bg-transparent text-center w-12"
                            onChange={(e) => {
                              const updatedBasket = [...basket];
                              updatedBasket[index].quantity = parseInt(e.target.value);
                              setBasket(updatedBasket);
                            }}
                          />
                          <button
                            onClick={() => changeProductQuantity("increase", item.product["PRODUCT.CODE"], basket, setBasket)}
                            className="border-gray-300 border-l px-1"
                          >
                            +
                          </button>
                        </div>
                        
                      </div>
                    </div>
                    
                  </td>
                  <td className="text-sm text-center">
                    <div className="relative">
                      <div className="flex items-center justify-center gap-2">
                        <p className="text-sm text-center">{item.product["Total Stock"].toLocaleString()} (100)</p>
                        <div className="flex items-center justify-center bg-widget-green text-xs rounded-full px-2 py-1 group">
                          <i className="fas fa-info"></i>
                        </div>
                      </div>
                      <div className="absolute top-6 right-0 bg-white rounded-md shadow-md opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50 w-auto pb-2 border border-gray-300"> 
                        <h3 className="font-semibold text-sm text-center py-2">Product Stock Levels</h3>
                        <div className="table w-full text-left">
                          <thead>
                            <tr>
                              <th className="border-b border-gray-400 text-center text-sm pl-4">Branch</th>
                              <th className="border-b border-gray-400 text-center text-sm px-1 shrink-0 no-wrap">Stock</th>
                              <th className="border-b border-gray-400 text-center text-sm px-1 pr-4">Incoming</th>
                            </tr>
                          </thead>
                          <tbody>
                            {["Bournemouth", "Hull", "Sheffield"].map((branch: string, index: number) => (
                              <tr key={index}>
                                <td><p className="text-sm text-center px-1 pl-4">{branch}</p></td>
                                <td><p className="text-sm text-center px-1">{(item.product as any)[`${branch.toUpperCase()}`].toLocaleString()}</p></td>
                                <td><p className="text-sm text-center px-1 pr-4">33</p></td>
                              </tr>
                            ))}
                          </tbody>
                        </div>
                      </div> 
                    </div>
                  </td>
                  <td className="text-sm text-center">£{item.product["COST.PRICE"].toFixed(2)}</td>
                  <td className="text-sm text-center">{
                    item.product["Terms Discount"] 
                      ? <div className="bg-widget-green text-widget-green font-bold px-2 py-1 rounded-full w-20 mx-auto">{(item.product["Terms Discount"] * 100).toFixed(2) + "%"}</div>
                      : "None"
                  }</td>
                  <td>
                    <div className="flex justify-center">
                      <div className="flex items-center justify-center border border-gray-300 py-1 rounded-md w-24">
                        <input 
                          type="number"
                          value={item.tax === null || item.tax === undefined ? "" : item.tax} 
                          onChange={(e) => {
                            const updatedBasket = [...basket];
                            updatedBasket[index].tax = parseFloat(e.target.value);
                            setBasket(updatedBasket);
                          }}
                          className="bg-transparent text-sm text-center w-12"
                        />
                        <p className="text-sm ml-2">%</p>
                      </div>
                    </div>
                  </td>
                  <td className="text-sm text-center">£{((item.basketPrice + (item.basketPrice * (item.tax || 0) / 100)) * (item.quantity || 0)).toFixed(2)}</td>
                  <td>
                    <div className="w-full flex items-center justify-center pr-2 gap-2">
                      <div className="relative">
                        {/* add a button that when hovered over shows the product details */}
                        <button 
                          className="btn btn-secondary"
                          onClick={() => {
                            setActiveBasketItem(item.product["PRODUCT.CODE"]);
                          }}
                        >
                          <i className="fas fa-clock-rotate-left"></i>
                        </button>
                        <div className="absolute top-12 right-0 bg-white rounded-md shadow-md opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50 w-auto pb-2 border border-gray-300"> 
                          <h3 className="font-semibold text-sm text-center py-2">Sales History</h3>
                          <div className="table w-full text-left">
                            <thead>
                              <tr>
                                <th className="border-b border-gray-400 text-center text-sm pl-4">Date</th>
                                <th className="border-b border-gray-400 text-center text-sm px-1">Price</th>
                                <th className="border-b border-gray-400 text-center text-sm px-1">Quantity</th>
                                <th className="border-b border-gray-400 text-center text-sm pr-4">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><p className="text-sm text-center px-1 pl-4">05/09/24</p></td>
                                <td><p className="text-sm text-center px-1">£13.43</p></td>
                                <td><p className="text-sm text-center px-1">12</p></td>
                                <td><p className="text-sm text-center px-1 pr-4">£161</p></td>
                              </tr>
                              <tr>
                                <td><p className="text-sm text-center px-1 pl-4">01/09/24</p></td>
                                <td><p className="text-sm text-center px-1">£13.35</p></td>
                                <td><p className="text-sm text-center px-1">100</p></td>
                                <td><p className="text-sm text-center px-1 pr-4">£1335</p></td>
                              </tr>
                              <tr>
                                <td><p className="text-sm text-center px-1 pl-4">28/08/24</p></td>
                                <td><p className="text-sm text-center px-1">£14</p></td>
                                <td><p className="text-sm text-center px-1">1</p></td>
                                <td><p className="text-sm text-center px-1 pr-4">£14</p></td>
                              </tr>
                            </tbody>
                          </div>
                        </div> 
                      </div>

                      <button 
                        className="btn btn-negative"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeItemFromBasket(item.product["PRODUCT.CODE"], basket, setBasket, setActiveBasketItem, activeBasketItem);
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SalesOrder;