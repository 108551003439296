import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';

import { CustomTooltip, colours, yAxisFormatter, CustomLegend } from './utils';

const SimpleLineChart = ({ chartData, metric }: any) => {
  
  return (
    <>
      <ResponsiveContainer height={200}>
        <LineChart
          width={200}
          height={200}
          data={chartData}
        >
          <CartesianGrid stroke="#9AA3B840" horizontal={true} vertical={false} />
          <XAxis dataKey="name" tick={{ fontSize: 10, fill: "#9AA3B8" }} axisLine={false} tickLine={false} />
          <YAxis 
            tick={{ fontSize: 12, fill: "#9AA3B8" }} 
            axisLine={false} 
            tickLine={false} 
            tickFormatter={(value) => yAxisFormatter(value, metric)}
          />
          <Tooltip 
            content={(props) => 
              <CustomTooltip 
                {...props} 
                lineNames={Object.keys(chartData[0]).filter((key) => key !== 'name')} 
                metric={metric}
              />
            } 
          />
          {Object.keys(chartData[0]).filter((key) => key !== 'name').map((key, index) => (
            <Line 
              dataKey={(data) => (data[key] === 0 ? undefined : data[key])}
              stroke={colours[index]}
              fill={colours[index]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>

      <CustomLegend payload={Object.keys(chartData[0]).filter((key) => key !== 'name')} />
    </>
  );
}

export default SimpleLineChart;