import React, { useEffect, useState } from 'react';

import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  Legend,
} from 'recharts';

import { CustomTooltip, colours, yAxisFormatter, CustomLegend } from './utils';

const SimpleBarChart = ({ chartData, includeAverageLine, metric }: any) => {
  const [dataForChart, setDataForChart] = useState<any[]>([]);

  useEffect(() => {
    console.log(chartData);

    if (includeAverageLine) {
      const average = chartData.reduce((acc: number, curr: any) => acc + curr[metric], 0) / chartData.length;
      
      chartData.forEach((data: any) => {
        data["Average"] = average;
      });
    }

    setDataForChart(chartData);
  }, [chartData, includeAverageLine, metric]);

  if (dataForChart.length === 0) {
    return null
  }

  return (
    <>
      <ResponsiveContainer height={200}>
        <ComposedChart
          width={200}
          height={200}
          data={dataForChart}
          barSize={10}
        >
          <CartesianGrid stroke="#9AA3B840" horizontal={true} vertical={false} />
          <XAxis dataKey="name" tick={{ fontSize: 10, fill: "#9AA3B8" }} axisLine={false} tickLine={false} />
          <YAxis 
            tick={{ fontSize: 12, fill: "#9AA3B8" }} 
            axisLine={false} 
            tickLine={false} 
            tickFormatter={(value) => yAxisFormatter(value, metric)}
          />
          <Tooltip content={(props) => <CustomTooltip {...props} lineNames={Object.keys(dataForChart[0]).filter((key) => key !== 'name')} metric={metric} />} />
          {Object.keys(dataForChart[0]).filter((key) => key !== 'name' && key !== "Average").map((key, index) => (
            <Bar dataKey={key} fill={colours[index]} radius={[10, 10, 0, 0]} /> 
          ))}
          {includeAverageLine && (
            <Line 
              type="monotone" 
              dataKey="Average" 
              stroke={colours[Object.keys(dataForChart[0]).filter((key) => key !== 'name').length - 1]}
              dot={false}  // Optional: Hides dots on the line
              strokeDasharray={"5 5"}  // Optional: Adds a dashed line
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>

      <CustomLegend payload={Object.keys(dataForChart[0]).filter((key) => key !== 'name')} />
    </>
  );
}

export default SimpleBarChart;