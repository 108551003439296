import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useEcommerceContext } from './context';

import { handleProductSelect, isProductInBasket, removeItemFromBasket } from '../../components/basketUtils';

import Pagination from '../../components/pagination';

import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd } from "../../utils";
import { Product } from '../../types';

import EcommerceNav from './nav';

const SalesHomePage = () => {
  const { basket, setBasket } = useEcommerceContext();
  const [activeBasketItem, setActiveBasketItem] = useState("");
  useEffect(() => {
    console.log(basket, activeBasketItem)
  }, [basket, activeBasketItem]);

  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [productCategory, setProductCategory] = useState("Solar Panels");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();
        setProductData(products);
        setFilteredAndSearchedProducts(products);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredAndSearchedProducts(productData
      .filter((product: Product) => product['PROD.GROUP'] === productCategory && product["PICT.FLAG"] !== "0")
    );
  }, [productCategory, productData]);

  return (
    <div className="flex flex-col flex-grow gap-4 bg-white">
      <EcommerceNav />

      <div className="container mx-auto flex flex-col items-center justify-center gap-4">
        <h3 className="font-bold text-lg">Shop by Brand</h3>

        <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-x-2 gap-y-12">
            {[
            { image: "aiko", link: "AIKO" },
            { image: "canadianSolar", link: "Canadian Solar" },
            { image: "duracell", link: "Duracell Energy" },
            { image: "ecoflow", link: "Ecobat" },
            { image: "enphase", link: "Enphase" },
            { image: "foxess", link: "Fox ESS" },
            { image: "givEnergy", link: "GivEnergy" },
            { image: "hyundai", link: "Hyundai" },
            { image: "JAsolar", link: "JA Solar" },
            { image: "jinko", link: "Jinko" },
            { image: "mountingSystems", link: "Mounting Systems" },
            { image: "myenergi", link: "myenergi" },
            { image: "pylonTech", link: "Puredrive" },
            { image: "solarEdge", link: "SolarEdge" },
            { image: "solax", link: "SolaX" },
            { image: "solis", link: "Solis" },
            { image: "sunSynk", link: "Sunsynk" },
            { image: "tigo", link: "Tigo" }
            ].map((brand, index) => (
            <Link to={`/ecommerce/products/?brand=${brand.link}`} key={index}>
              <img src={`/supplierImages/${brand.image}.svg`} alt={brand.link} className="w-full cursor-p" />
            </Link>
            ))}  
        </div>  
      </div>
      
      <div className="container mx-auto flex items-center justify-center gap-4 mt-12">
        {["Solar Panels", "Power Inverters", "Mount Brackets", ].map((category: string, index: number) => (
          <div 
            key={index} 
            className={`
              cursor-pointer p-2 border-b-2 font-bold text-lg
              ${productCategory === category ? "text-black border-black" : "text-gray-400 border-transparent hover:text-black hover:border-black"}
            `}
            onClick={() => setProductCategory(category)}
          >
            {category}
          </div>  
        ))}
      </div>

      <div className="container mx-auto py-12">
        {fetching 
          ? <div className="flex justify-center items-center h-full">
              <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
            </div>
          : <>
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-x-4 md:gap-x-8 gap-y-8">
                {sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, "DESCRIPTION")
                  .sort((a: Product, b: Product) => {
                    if (a["Total Stock"] > 0 && b["Total Stock"] === 0) return -1;
                    if (a["Total Stock"] === 0 && b["Total Stock"] > 0) return 1;
                    return 0;
                  })
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map((product: Product, rowIndex: number) => {
                    let itemIsInBasket = isProductInBasket(product['PRODUCT.CODE'], basket);
                    return (
                    <div className="flex items-center flex-col gap-2">
                      <img src={product["PICT.FLAG"]} alt={product.DESCRIPTION} className="h-48 md:h-44 lg:h-60 w-auto rounded-md" />
                      <div className="flex gap-2 justify-between items-start">
                        <div>
                          <p className="font-semibold">{product.DESCRIPTION}</p>
                          <p className="text-lg">£{product["LIST.PRICE"]}</p>
                        </div>

                        <button 
                          className={`${itemIsInBasket ? "bg-red-400" : "bg-black"} text-white p-2 px-3`}
                          onClick={() => {
                            if (itemIsInBasket) {
                              removeItemFromBasket(
                                product['PRODUCT.CODE'],
                                basket,
                                setBasket,
                                setActiveBasketItem,
                                activeBasketItem
                              );
                            }
                            else {
                              handleProductSelect(
                                product['PRODUCT.CODE'], 
                                productData,
                                basket, 
                                setBasket, 
                                setActiveBasketItem, 
                                "sale"
                              );
                            }
                          }}
                        >
                          <i className={`fa-solid fa-basket-shopping-${itemIsInBasket ? "minus" : "plus"}`}></i>
                        </button>
                      </div>
                    </div>
                  )}
                )}
              </div>

              <Pagination 
                numberOfEntries={filteredAndSearchedProducts.length}
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />
            </>

        }
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-1 my-12">
        {[
          { title: "Solar Panels", icon: "solar-panels", category: "Solar Panels" },
          { title: "Power Inverters", icon: "inverters", category: "Power Inverters" },
          { title: "Battery Storage", icon: "battery-storage", category: "Batteries" },
          { title: "EV Chargers", icon: "ev-charger", category: "Vehicle Battery Chargers" },
        ].map((category, index) => (
          <div 
            className="w-full aspect-square bg-cover bg-center relative cursor-pointer"
            style={{ backgroundImage: `url(/categoryImages/${category.icon}.webp)` }}
          >
            <div className="bg-black bg-opacity-50 p-4 md:p-8 w-full h-full flex flex-col justify-end items-start gap-1 md:gap-2">
              <h3 className="text-white text-xl md:text-2xl">{category.title}</h3>
              <Link to={`/ecommerce/products/?category=${category.category}`} className="bg-white text-black px-4 py-2">Shop Now</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SalesHomePage;