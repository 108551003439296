import { BasketItem, Product } from '../types';

export const handleGPupdate = (
  e: React.ChangeEvent<HTMLInputElement>,
  index: number,
  basket: BasketItem[],
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>
) => {
  const updatedBasket = [...basket];
  let userEnteredGp = parseFloat(e.currentTarget.value);
  let item = updatedBasket[index];
  
  let resultingBasketPrice = (userEnteredGp && userEnteredGp > 0)
    ? item.product["COST.PRICE"] + (item.product["COST.PRICE"] * (parseFloat(e.currentTarget.value) / 100))
    : item.product["postTermsPrice"] || item.product["LIST.PRICE"];

  updatedBasket[index].basketPrice = parseFloat(resultingBasketPrice.toFixed(2));
  updatedBasket[index].gp = userEnteredGp;

  setBasket(updatedBasket);
};

export const handleGPchange = (
  e: React.KeyboardEvent<HTMLInputElement>, 
  index: number,
  basket: BasketItem[],
  setActiveBasketItem: (productCode: string) => void
) => {
  if (e.key === "Enter") {
    e.preventDefault();
    if (index !== basket.length - 1) {
      setActiveBasketItem(basket[index + 1].product["PRODUCT.CODE"]);
      let nextItemGPinput = document.getElementById(`${basket[index + 1].product["PRODUCT.CODE"]}-GP`);
      if (nextItemGPinput) nextItemGPinput.focus();
    }
    else {
      let continueButton = document.getElementById("continueButton");
      if (continueButton) continueButton.focus();
    }
  }
};

export const calculateSalesOrderBasketTotals = (
  basket: BasketItem[]
) => {
  console.log(basket);
  let subtotal = 0;
  let cost = 0
  basket.forEach((item) => {
    if (!item.quantity) return;
    subtotal += item.basketPrice * item.quantity;
    cost += item.product["COST.PRICE"] * item.quantity;
  });

  let profit = subtotal - cost;
  let grossProfit = (profit / cost) * 100;

  console.log(subtotal, cost, profit, grossProfit);
  return { subtotal, profit, grossProfit };
}

export const calculatePurchaseOrderBasketTotals = (
  basket: BasketItem[],
) => {
  let totalCost = 0;
  let totalQuoted = 0;
  basket.forEach((item) => {
    if (!item.quantity) return;
    let tax = item.tax ? item.tax / 100 : 0;
    console.log(item.basketPrice, item.product["COST.PRICE"], item.quantity);
    totalQuoted += (item.basketPrice + (item.basketPrice * tax)) * item.quantity;
    totalCost += (item.product["COST.PRICE"] + (item.product["COST.PRICE"] * tax)) * item.quantity;
  });

  let discount = totalCost - totalQuoted;
  let discountPercentage = (totalCost - totalQuoted) / totalCost * 100;

  console.log(totalCost, totalQuoted, discount, discountPercentage);

  return { totalQuoted, discount, discountPercentage };
}

export const isProductInBasket = (id: string, basket: BasketItem[]) => {
  return basket.some((item) => item.product["PRODUCT.CODE"] === id);
}

export const handleProductSelect = (
  id: string,
  productData: Product[],
  basket: BasketItem[],
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>,
  setActiveBasketItem: (productCode: string) => void,
  screen: "purchase" | "sale"
) => {
  console.log(id, productData, basket);
  const latestProductData = [...productData];
  const product = latestProductData.find((product) => product["PRODUCT.CODE"] === id);
  if (!product) return;

  console.log(product);

  const itemIndex = basket.findIndex((item) => item.product["PRODUCT.CODE"] === id);
  if (itemIndex > -1) {
    changeProductQuantity("increase", id, basket, setBasket);
  } else {
    let basketPrice = screen === "sale" 
      ? product["postTermsPrice"] || product["LIST.PRICE"]
      : product["Terms Discount"]
        ? parseFloat((product["COST.PRICE"] - (product["COST.PRICE"] * product["Terms Discount"])).toFixed(2))
        : product["COST.PRICE"];
    let gp = ((basketPrice - product["COST.PRICE"]) / product["COST.PRICE"]) * 100;

    console.log(basketPrice, gp);
    setBasket([...basket, { product, quantity: null, basketPrice, gp }]);
    setActiveBasketItem(id);
  }
};

export const removeItemFromBasket = (
  id: string,
  basket: BasketItem[],
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>,
  setActiveBasketItem: (productCode: string) => void,
  activeBasketItem: string | null
) => {
  const updatedBasket = [...basket];
  const indexOfActiveItem = basket.findIndex((item) => item.product["PRODUCT.CODE"] === activeBasketItem);
  const indexOfItem = basket.findIndex((item) => item.product["PRODUCT.CODE"] === id);

  console.log(indexOfItem, indexOfActiveItem, basket.length - 1);

  if (indexOfItem === indexOfActiveItem && basket.length > 1) {
    indexOfActiveItem === basket.length - 1
      ? setActiveBasketItem(basket[indexOfItem - 1].product["PRODUCT.CODE"])
      : setActiveBasketItem(basket[indexOfItem + 1].product["PRODUCT.CODE"])
  }
  console.log(indexOfItem, basket[indexOfItem + 1]);
  updatedBasket.splice(basket.findIndex((item) => item.product["PRODUCT.CODE"] === id), 1);
  setBasket(updatedBasket);
}

export const changeProductQuantity = (
  change: "increase" | "decrease" | number,
  id: string,
  basket: BasketItem[],
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>,
) => {
  const updatedBasket = [...basket];
  let itemInBasket = updatedBasket.find((item) => item.product["PRODUCT.CODE"] === id);
  if (!itemInBasket) return;

  if (itemInBasket.quantity === null) itemInBasket.quantity = 1;
  else {
    if (change === "increase") itemInBasket.quantity += 1;
    else if (change === "decrease") itemInBasket.quantity -= 1;
    else itemInBasket.quantity = change;
  }

  setBasket(updatedBasket);
}

