import React from 'react';
import { useScreen } from '../context';
import LinkWithDirtyProtection from '../components/linkWrapper';

const ConfigPage = () => {
  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("CONFIG");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  const configOptions = [
    {
      "title": "Allowable Couriers",
      "link": "/config/couriers",
      "description": "Enable couriers across the system and set your preferred courier and rates.",
      "icon": "truck"
    },
    {
      "title": "API Status of System",
      "link": "/config/api-status",
      "description": "View the status of the current APIs within your system and report any fault.",
      "icon": "server"
    },
    {
      "title": "Product Categories",
      "link": "/config/product-categories",
      "description": "Set product categories across your system to enable better groupings.",
      "icon": "tags"
    },
    {
      "title": "Another Config Panel",
      "link": "/config/another-panel-1",
      "description": "Add description here relevant to config item.",
      "icon": "eye"
    },
    {
      "title": "Another Config Panel",
      "link": "/config/another-panel-2",
      "description": "Add description here relevant to config item.",
      "icon": "cogs"
    },
    {
      "title": "Another Config Panel",
      "link": "/config/another-panel-3",
      "description": "Add description here relevant to config item.",
      "icon": "chart-bar"
    }
  ];

  return (
    <div>
      <h1 className="text-2xl md:text-4xl font-bold">System Config</h1>
      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
        {configOptions.map((child, index) => (
          <div
            className="flex flex-col dashboard-card bg-base-100 p-4 cursor-pointer hover:bg-gray-100"
            key={index}
          >
            <div className="flex items-center gap-4">
              <div className="bg-widget-blue text-widget-blue rounded-xl p-2 md:p-3 md:px-4 text-xl md:text-2xl">
                <i className={`fas fa-${child.icon}`}></i>
              </div>

              <h3 className="text-lg md:text-2xl font-semibold">
                {child.title}
              </h3>
            </div>
            
            <p className="text-sm md:text-lg mt-4">{child.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ConfigPage;