import React, { useEffect, useState } from 'react';
import { useScreen } from '../../context';

import ChartCard from '../../components/widgets/chartCard';
import SimpleMetricCard from '../../components/widgets/simpleMetricCard';
import DoubleMetricCard from '../../components/widgets/doubleMetricCard';
import TopProducts from '../../components/widgets/topProductsTable';
import AnnouncementsCard from '../../components/widgets/announcementsCard';

import DailyTotals from '../../components/widgets/dailyTotals';
import WeeklyTotals from '../../components/widgets/weeklyTotals';
import MonthlyTotals from '../../components/widgets/monthlyTotals';

import { topProductsData } from '../../dummyData';
import { fetchDashboardData } from '../../api';
import { Order } from '../../types';

import { groupOrders, processDataIntoWeeklyChanges } from './utils';

const DashboardPage = () => {
  const [fetching, setFetching] = useState(true);
  const [totalOrders, setTotalOrders] = useState({ thisWeek: 0, lastWeek: 0 });
  const [totalSales, setTotalSales] = useState({ thisWeek: 0, lastWeek: 0 });
  const [totalProfit, setTotalProfit] = useState({ thisWeek: 0, lastWeek: 0 });

  const [ordersThisYear, setOrdersThisYear] = useState<Order[]>([]);
  const [ordersLastYear, setOrdersLastYear] = useState<Order[]>([]);
  const [ordersThisWeek, setOrdersThisWeek] = useState<Order[]>([]);
  const [ordersLastWeek, setOrdersLastWeek] = useState<Order[]>([]);

  const { setScreenId } = useScreen();
  setScreenId("DASH");

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching dashboard data...');
        const fetchResponse = await fetchDashboardData();
        if (!fetchResponse) throw new Error('No data returned from fetchDashboardData');
        
        const orders = fetchResponse.orders;

        const grouppedOrders = groupOrders(orders);
        const weeklyChanges = processDataIntoWeeklyChanges(grouppedOrders.ordersThisWeek, grouppedOrders.ordersLastWeek);

        setOrdersThisYear(grouppedOrders.ordersThisYear);
        setOrdersLastYear(grouppedOrders.ordersLastYear);
        setOrdersThisWeek(grouppedOrders.ordersThisWeek);
        setOrdersLastWeek(grouppedOrders.ordersLastWeek);
        setTotalOrders(weeklyChanges.totalOrders);
        setTotalProfit(weeklyChanges.totalProfit);
        setTotalSales(weeklyChanges.totalSales);
      
        console.log("Data processed.");
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl md:text-4xl font-bold">Dashboard</h1>

      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
        <div className="grid md:grid-cols-3 gap-4 md:col-span-2">
          <SimpleMetricCard
            identifier='Total HDM'
            metric='Orders'
            count={totalOrders.thisWeek}
            change={totalOrders.thisWeek - totalOrders.lastWeek}
            period='week'
            icon='cart-shopping'
            chartColor='black'
          />

          <SimpleMetricCard
            identifier='Total HDM'
            metric='Sales'
            count={totalSales.thisWeek}
            change={totalSales.thisWeek - totalSales.lastWeek}
            period='week'
            icon='cash-register'
            chartColor='pink'
          />

          <SimpleMetricCard
            identifier='Total HDM'
            metric='Profit'
            count={totalProfit.thisWeek}
            change={totalProfit.thisWeek - totalProfit.lastWeek}
            period='week'
            icon='arrow-trend-up'
            chartColor='green'
          />
        </div>

        <DoubleMetricCard
          identifier='My'
          metricOne='Orders'
          metricOneChange={12}
          metricTwo='Sales'
          metricTwoChange={15121}
          icon='chart-line'
          chartColor='blue'
        />

        <DailyTotals ordersThisWeek={ordersThisWeek} ordersLastWeek={ordersLastWeek} />
        <WeeklyTotals ordersThisYear={ordersThisYear} />
        <MonthlyTotals ordersLastYear={ordersLastYear} ordersThisYear={ordersThisYear} defaultMetric={null} id={"monthly-chart"} />

      </div>
      
      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
        <div className="md:col-span-2">
          <TopProducts ordersThisYear={ordersThisYear} />
        </div>
        
        <AnnouncementsCard
          announcements={[
            { author: "Adam F", date: "22/05/24 3:12pm", content: "Thanks for all the hard work during stocktake. We would like to take you all for a drink as a thank you and it will be this Friday at 8! See you there. Adam"
            },
            { author: "Claire S", date: "22/05/24 1:06pm", content: "Please can we start to upsell fixing mounts with all panels. We are missing a big opportunity on the smaller items and need to understand where each customer is currently purchasing. "
            },
            { author: "Tayah B", date: "22/05/24 9:01pm", content: "Make sure to check SolarScape before committing to larger orders.We need to start to utilise the buys ins! Tayah"
            }
          ]}
        />
      </div>
    </div>
  );
}

export default DashboardPage;