import React, { useEffect, useState } from 'react';

import { fetchProductData } from '../utils';
import { Product, BasketItem } from '../types';
import { handleProductSelect, removeItemFromBasket, changeProductQuantity } from './basketUtils';
import SearchBar from './searchBar';
import Pagination from './pagination';
import ModalWithChildren from './modalWithChildren';

import { searchProducts } from '../api';

type ProductModalProps = {
  basket: BasketItem[];
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>;
  setActiveBasketItem: (productCode: string) => void;
  activeBasketItem: string | null;
  modalIsOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  screen: "purchase" | "sale";
};

const ProductsSearchModal = ({ basket, setBasket, setActiveBasketItem, activeBasketItem, modalIsOpen, setModalOpen, screen }: ProductModalProps) => {
  const [fetching, setFetching] = useState(false);
  const [newEQSIopen, setNewEQSIopen] = useState(false);
  const [productData, setProductData] = useState([] as any[]);
  const [childQuery, setChildQuery] = useState("" as string | null);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();

        products.forEach((product, index) => {
          Math.random() > 0.6
            ? product["Terms Discount"] = (Math.random() * 0.35 + 0.05)
            : product["Terms Discount"] = 0

          product["postTermsPrice"] = product["Terms Discount"] 
            ? parseFloat((product["LIST.PRICE"] - (product["LIST.PRICE"] * product["Terms Discount"])).toFixed(2))
            : product["LIST.PRICE"];
        })

        // Sort products by stock, with 0 stock at the end
        products.sort((a, b) => {
          if (a["Total Stock"] === 0) return 1;
          if (b["Total Stock"] === 0) return -1;
          return (b["Total Stock"] as number) - (a["Total Stock"] as number);
        });

        setProductData(products);
        setFilteredAndSearchedProducts(products);
        setFetching(false);
      } catch (error) {
        console.error('There was a problem fetching customer data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (filteredAndSearchedProducts.length === 1) {
      setActiveBasketItem(filteredAndSearchedProducts[0]["PRODUCT.CODE"]);
      handleProductSelect(filteredAndSearchedProducts[0]["PRODUCT.CODE"], productData, basket, setBasket, setActiveBasketItem, screen)
      setTimeout(() => {
        const input = document.getElementById(`quantity-${filteredAndSearchedProducts[0]["PRODUCT.CODE"]}`);
        input?.focus();
      }, 100);
    }
  }, [filteredAndSearchedProducts]);

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e.key);
    if (e.key === 'Enter') {
      console.log("Enter pressed");
      console.log(childQuery)
      setChildQuery(childQuery === null ? "" : null);
      const searchBar = document.getElementById("modal-search-bar");
      searchBar?.focus();
    }
  };

  return (
    <ModalWithChildren modalOpen={modalIsOpen} setModalOpen={setModalOpen}>
      <div className="flex flex-col flex-grow gap-4">
        {!fetching && <>
          <SearchBar initialData={productData} childQuery={childQuery} setResults={setFilteredAndSearchedProducts} searchFunction={searchProducts} />
          <div className="flex flex-col gap-4 dashboard-card bg-base-100 p-2 md:p-4 rounded-lg">
            <div style={{maxHeight: "525px"}} className="overflow-y-scroll pr-4">
              <table className="table-auto w-full">
                <thead className="sticky top-0 bg-white">
                  <tr>
                    <th className="text-left px-1 text-sm">Product</th>
                    <th className="text-left px-1 text-sm">SKU</th>
                    <th className="text-left px-1 text-sm">Category</th>
                    <th className="text-left px-1 text-sm">Cost Price</th>
                    <th className="text-left px-1 text-sm">LIST.PRICE</th>
                    <th className="text-left px-1 text-sm">Terms</th>
                    <th className="text-left px-1 text-sm">Customer Price</th>
                    <th className="text-left px-1 text-sm">Stock</th>
                    <th className="text-center px-2 text-sm ">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAndSearchedProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item: Product, rowIndex: number) => {
                    let itemInCurrentBasket = basket.find((basketItem: BasketItem) => basketItem.product["PRODUCT.CODE"] === item["PRODUCT.CODE"])

                    return (
                    <tr key={rowIndex}>
                      <td>
                        <div className="flex gap-4 items-center pr-2">
                          <img src={item["PICT.FLAG"]} alt={item.DESCRIPTION} className="w-14 h-14 rounded-md" />
                          <div>
                            <p className="font-semibold text-sm">{item.DESCRIPTION.length > 45 ? item.DESCRIPTION.slice(0, 45) + "..." : item.DESCRIPTION}</p>
                            <p className="text-xs">{item["SUPPLIER"]}</p>
                          </div>
                        </div>
                      </td>
                      <td><p className="text-sm text-[#3A5BFF]  ">{item["PRODUCT.CODE"]}</p></td>
                      <td><p className="text-sm px-1">{item["PROD.GROUP"]}</p></td>
                      <td><p className="text-sm px-1">£{item["COST.PRICE"]}</p></td>
                      <td><p className="text-sm px-1">£{item["LIST.PRICE"]}</p></td>
                      <td><p className="text-sm px-1">{item["Terms Discount"] ? (item["Terms Discount"] * 100).toFixed(2) + "%" : "None"}</p></td>
                      <td><p className="text-sm px-1">£{item["postTermsPrice"]}</p></td>
                      <td><p className="text-sm px-1">{item["Total Stock"]?.toLocaleString()}</p></td>
                      <td>
                        {itemInCurrentBasket 
                          ? <div className="flex items-center pl-4">
                              <input
                                type="number"
                                className="input w-14 border-y border-l border-gray-300 rounded-l-md h-8 text-center outline-none"
                                id={`quantity-${item["PRODUCT.CODE"]}`}
                                value={itemInCurrentBasket.quantity || undefined}
                                onChange={(e) => changeProductQuantity(parseFloat(e.target.value), item["PRODUCT.CODE"], basket, setBasket)}
                                onKeyDown={(e) => handleKeyDown(e)}
                              />
                          
                              <button
                                onClick={() => removeItemFromBasket(item["PRODUCT.CODE"], basket, setBasket, setActiveBasketItem,   activeBasketItem)}
                                className="bg-red-400 text-white px-2 flex justify-center rounded-r-md h-8 items-center"
                              >
                                <i className="fa-solid fa-trash-xmark text-xs"></i>
                              </button>
                            </div>
                          : <button 
                              onClick={() => {
                                handleProductSelect(item["PRODUCT.CODE"], productData, basket, setBasket, setActiveBasketItem, screen)
                                setTimeout(() => {
                                  const input = document.getElementById(`quantity-${item["PRODUCT.CODE"]}`);
                                  input?.focus();
                                }, 100);
                              }}
                              className="btn btn-primary w-14 flex justify-center ml-4"
                            >
                              <i className="fa-solid fa-basket-shopping-plus"></i>
                            </button>
                        }
                      </td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
            
            <div className="flex items-center justify-between mt-auto">            
              <Pagination 
                numberOfEntries={productData.length} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />

              <button 
                onClick={() => setModalOpen(false)} 
                className="btn btn-primary"
              >
                Continue
              </button>
            </div>
          </div>
        </>}
      </div>
    </ModalWithChildren>
  );
}

export default ProductsSearchModal;