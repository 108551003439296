import React, { useEffect, useState, useContext } from 'react';
import { useEcommerceContext } from './context';

import { handleProductSelect, isProductInBasket, removeItemFromBasket } from '../../components/basketUtils';

import Pagination from '../../components/pagination';

import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd } from "../../utils";
import { Product } from '../../types';

import EcommerceNav from './nav';

const SalesProductsPage = () => {
  const { basket, setBasket } = useEcommerceContext();
  const [activeBasketItem, setActiveBasketItem] = useState("");
  useEffect(() => {
    console.log(basket, activeBasketItem)
  }, [basket, activeBasketItem]);

  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [productCategory, setProductCategory] = useState("Solar Panels");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;

  const searchParams = new URLSearchParams(window.location.search);

  const existingCategoryFilters = searchParams.get("category")?.split(",") || [];
  const [filterCategories, setFilterCategories] = useState([
    { title: "Solar Panels", enabled: existingCategoryFilters.includes("Solar Panels"), category: "Solar Panels" },
    { title: "Power Inverters", enabled: existingCategoryFilters.includes("Power Inverters"), category: "Power Inverters" },
    { title: "Battery Storage", enabled: existingCategoryFilters.includes("Battery Storage"), category: "Batteries" },
    { title: "EV Chargers", enabled: existingCategoryFilters.includes("EV Chargers"), category: "Vehicle Battery Chargers" },
  ]);

  const existingBrandFilters = searchParams.get("brand")?.split(",") || [];
  const [filterBrands, setFilterBrands] = useState([
    { title: "Duracell Energy", enabled: existingBrandFilters.includes("Duracell Energy"), brand: "Duracell Energy" },
    { title: "EcoFlow", enabled: existingBrandFilters.includes("EcoFlow"), brand: "EcoFlow" },
    { title: "Enphase", enabled: existingBrandFilters.includes("Enphase"), brand: "Enphase" },
    { title: "Fox ESS", enabled: existingBrandFilters.includes("Fox ESS"), brand: "Fox ESS" },
    { title: "GivEnergy", enabled: existingBrandFilters.includes("GivEnergy"), brand: "GivEnergy" },
    { title: "Growatt", enabled: existingBrandFilters.includes("Growatt"), brand: "Growatt" },
    { title: "myenergi", enabled: existingBrandFilters.includes("myenergi"), brand: "myenergi" },
    { title: "SolarEdge", enabled: existingBrandFilters.includes("SolarEdge"), brand: "SolarEdge" },
    { title: "SolaX", enabled: existingBrandFilters.includes("SolaX"), brand: "SolaX" },
    { title: "Solis", enabled: existingBrandFilters.includes("Solis"), brand: "Solis" },
    { title: "Sunsynk", enabled: existingBrandFilters.includes("Sunsynk"), brand: "Sunsynk" },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();
        setProductData(products);
        setFilteredAndSearchedProducts(products);

        const uniqueSuppliers = Array.from(new Set(products.map(product => product["SUPPLIER"])));
        const uniqueProdGroups = Array.from(new Set(products.map(product => product["PROD.GROUP"])));

        console.log('Unique Suppliers:', uniqueSuppliers);
        console.log('Unique Product Groups:', uniqueProdGroups);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredAndSearchedProducts(productData
      .filter((product: Product) => {
        const categoryFilterApplied = filterCategories.some(category => category.enabled);
        const brandFilterApplied = filterBrands.some(brand => brand.enabled);

        const categoryMatch = categoryFilterApplied
          ? filterCategories.some(category => category.enabled && category.category === product['PROD.GROUP'])
          : true;

        const brandMatch = brandFilterApplied
          ? filterBrands.some(brand => brand.enabled && brand.brand === product['SUPPLIER'])
          : true;

        return categoryMatch && brandMatch
      })
    );
  }, [filterCategories, filterBrands, productData]);

  return (
    <div className="flex flex-col flex-grow gap-4 bg-white">
      <EcommerceNav />
        
      <div className="container mx-auto py-12 flex gap-6 md:gap-10">
        <div className="shrink-0">
          <div className="shrink-0 sticky top-0 bg-gray-100 p-4 md:px-6">
            <h2 className="text-2xl font-bold">Search Filters</h2>

            <div className="mt-8 border-b border-gray-400 pb-2">
              <h3 className="text-lg font-semibold pl-1">Categories</h3>
            </div>
            <div className="mt-4 flex flex-col gap-1 pl-1">
              {filterCategories.map((category, index) => (
                <div key={category.title} className="flex items-center gap-2">
                  <input 
                    type="checkbox"
                    className="checkbox"
                    id={category.title}
                    checked={category.enabled}
                    onChange={(e) => {
                      setFilterCategories(prev => {
                        const newFilters = [...prev];
                        newFilters[index].enabled = e.target.checked;
                        return newFilters;
                      });
                    }}
                  />
                  <label htmlFor={category.title}>{category.title}</label>
                </div>
              ))}
            </div>

            <div className="mt-8 border-b border-gray-400 pb-2">
              <h3 className="text-lg font-semibold pl-1">Brands</h3>
            </div>
            <div className="mt-4 flex flex-col gap-1 pl-1">
              {filterBrands.map((brand, index) => (
                <div key={brand.title} className="flex items-center gap-2">
                  <input 
                    type="checkbox"
                    className="checkbox"
                    id={brand.title}
                    checked={brand.enabled}
                    onChange={(e) => {
                      setFilterBrands(prev => {
                        const newFilters = [...prev];
                        newFilters[index].enabled = e.target.checked;
                        return newFilters;
                      });
                    }}
                  />
                  <label htmlFor={brand.title}>{brand.title}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-grow">
          {fetching 
            ? <div className="flex justify-center items-center h-full">
                <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
              </div>
            : <>
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-x-4 md:gap-x-8 gap-y-8">
                  {sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, "DESCRIPTION")
                    .sort((a: Product, b: Product) => {
                      if (a["Total Stock"] > 0 && b["Total Stock"] === 0) return -1;
                      if (a["Total Stock"] === 0 && b["Total Stock"] > 0) return 1;
                      return 0;
                    })
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    .map((product: Product, rowIndex: number) => {
                      let itemIsInBasket = isProductInBasket(product['PRODUCT.CODE'], basket);
                      return (
                      <div className="flex items-center flex-col gap-2">
                        <img src={product["PICT.FLAG"]} alt={product.DESCRIPTION} className="h-48 md:h-44 lg:h-60 w-auto rounded-md" />
                        <div className="flex gap-2 justify-between items-start">
                          <div>
                            <p className="font-semibold">{product.DESCRIPTION}</p>
                            <p className="text-lg">£{product["LIST.PRICE"]}</p>
                          </div>

                          <button 
                            className={`${itemIsInBasket ? "bg-red-400" : "bg-black"} text-white p-2 px-3`}
                            onClick={() => {
                              if (itemIsInBasket) {
                                removeItemFromBasket(
                                  product['PRODUCT.CODE'],
                                  basket,
                                  setBasket,
                                  setActiveBasketItem,
                                  activeBasketItem
                                );
                              }
                              else {
                                handleProductSelect(
                                  product['PRODUCT.CODE'], 
                                  productData,
                                  basket, 
                                  setBasket, 
                                  setActiveBasketItem, 
                                  "sale"
                                );
                              }
                            }}
                          >
                            <i className={`fa-solid fa-basket-shopping-${itemIsInBasket ? "minus" : "plus"}`}></i>
                          </button>
                        </div>
                      </div>
                    )}
                  )}
                </div>

                <Pagination 
                  numberOfEntries={filteredAndSearchedProducts.length}
                  currentPage={currentPage} 
                  setCurrentPage={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                />
              </>

          }
        </div>
      </div>
    </div>
  );
}

export default SalesProductsPage;